import { useState, useEffect } from "react"

export const useFetch = (url) => {
  const [data, setData] = useState([]);
  const [loading,setLoading] = useState(false);
  useEffect(() => {
    getApiData(url);
  }, []);

  const getApiData = async (url) => {
    setLoading(true);
    try {
      const response = await fetch(url);
      if (response.ok) {
        const result = await response.json();
        setData(result);
      } else {
        console.error('API request failed:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('An error occurred while fetching the API:', error);
    }finally{
      setLoading(false)
    }
  };
  return { data, setData, loading }
}