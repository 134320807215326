import { Col, Container, Row } from "react-bootstrap";
import { GoArrowUpRight } from "react-icons/go";
import "../../styles/homebannernew.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import TalktoExpertModal from "./TalktoExpertModal";

const HomeBannerNew = () => {
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);

  const handleNavigate = () => {
    navigate("/pricing")
  }

  return (
    <div>
      <Container className="py-4 py-lg-5">
        <div
          style={{
            maxWidth: "798px",
            paddingBottom:"0rem",
          }}
          className="d-flex flex-column mx-auto gap-y-6"
        >
          {/* <h2 className="text-center heading-1 section-title">
            Get <span style={{ color: "#8DC63F" }}>Real-time</span> Store{" "}
            <span style={{ color: "#8DC63F" }}>Insights</span>
          </h2> */}
            <h2 className="text-center heading-1 section-title">
             Market Research <span style={{ color: "#8DC63F" }}> Platform for Your </span>Product/Service
          </h2>
          <p
            className="text-center pera mt-2"
            style={{ fontFamily: '"Roboto", sans-serif' }}
          >
           Get Insights from a pre-designed package covering different geographies & demographically divided by 5 lakh + field force to access your product or service. The bundled product helps you access the market at a pre-launch and post-launch stage
          </p>
          <h2 className="text-center heading-1 section-title" style={{fontSize:"1rem"}}>12+ years experience</h2>
          <div className="hero_button_container">
            <div className="justify-content-center d-flex gap-3 gap-lg-4 flex-column mt-2 flex-sm-row">
              <button className="button w-full button-primary" onClick={handleNavigate}>
                Explore Marketplace
              </button>
              <button className="button w-full button-outline-2" onClick={() =>setModalShow(true,)}>
                Talk to an expert
                <GoArrowUpRight size={28} />
              </button>
            </div>
          </div>
        </div>
        <Row className="position-relative  home_hero_wrapper_2">
          <Col sm={12} md={4} lg={5} xl={4} className="">
            <div className="in-depth-audit-report">
              <h3 className="heading-4 text-white">In-Depth Audit Reports</h3>
              <p>
                Receive comprehensive reports tailored to your store’s needs,
                ensuring precise insights and action plans
              </p>
              <div className="icon_container">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={36}
                  height={36}
                  viewBox="0 0 36 36"
                  fill="none"
                >
                  <path
                    d="M28.5 4.5H7.5C5.85 4.5 4.5 5.85 4.5 7.5V28.5C4.5 30.15 5.85 31.5 7.5 31.5H28.5C30.15 31.5 31.5 30.15 31.5 28.5V7.5C31.5 5.85 30.15 4.5 28.5 4.5ZM28.5 28.5H7.5V7.5H28.5V28.5Z"
                    fill="#FAFAFA"
                  />
                  <path d="M13.5 18H10.5V25.5H13.5V18Z" fill="#FAFAFA" />
                  <path d="M25.5 10.5H22.5V25.5H25.5V10.5Z" fill="#FAFAFA" />
                  <path d="M19.5 21H16.5V25.5H19.5V21Z" fill="#FAFAFA" />
                  <path d="M19.5 15H16.5V18H19.5V15Z" fill="#FAFAFA" />
                </svg>
              </div>
            </div>
            <div className="batch_2_tarif home_hero_item_2">
              <p className="heading-4 text-white" style={{ fontSize: "18px" }}>
                12+ years experience
              </p>
              <div className="icon_box">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={32}
                  height={32}
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <g clipPath="url(#clip0_1_2878)">
                    <path
                      d="M16.0016 1.33331L4.00159 6.66665V14.6666C4.00159 22.0666 9.12159 28.9866 16.0016 30.6666C22.8816 28.9866 28.0016 22.0666 28.0016 14.6666V6.66665L16.0016 1.33331ZM13.3349 22.6666L8.00159 17.3333L9.88159 15.4533L13.3349 18.8933L22.1216 10.1066L24.0016 12L13.3349 22.6666Z"
                      fill="#FAFAFA"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1_2878">
                      <rect
                        width={32}
                        height={32}
                        fill="white"
                        transform="translate(0.00158691)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
          </Col>
          <Col sm={12} md={4} lg={2} xl={4} className="">
            <img
              className="home_hero_women"
              src="/imgs/hero-women.webp"
              alt="women"
            />
          </Col>
          <Col sm={12} md={4} lg={5} xl={4} className="mt-4 mt-lg-4">
            <div className="batch_2_tarif">
              <p className="heading-4 text-white" style={{ fontSize: "18px" }}>
                Trusted by Global Brands
              </p>
              <div className="icon_box">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={22}
                  height={20}
                  viewBox="0 0 22 20"
                  fill="none"
                >
                  <path
                    d="M4.00159 9H0.00158691V20H4.00159V9Z"
                    fill="#FAFAFA"
                  />
                  <path
                    d="M15.0016 1.25C15.6516 0.49 16.6616 0 17.7016 0C19.5516 0 21.0016 1.45 21.0016 3.3C21.0016 5.57 18.0916 8.2 15.0016 11C11.9116 8.19 9.00159 5.56 9.00159 3.3C9.00159 1.45 10.4516 0 12.3016 0C13.3416 0 14.3516 0.49 15.0016 1.25Z"
                    fill="#FAFAFA"
                  />
                  <path
                    d="M19.0016 15H12.0016L9.91159 14.27L10.2416 13.33L12.0016 14H14.8216C15.4716 14 16.0016 13.47 16.0016 12.82C16.0016 12.33 15.6916 11.89 15.2316 11.71L7.97159 9H6.00159V18.02L13.0016 20L21.0116 17C21.0016 15.9 20.1116 15 19.0016 15Z"
                    fill="#FAFAFA"
                  />
                </svg>
              </div>
            </div>
            <div
              style={{
                background:
                  "radial-gradient(558.04% 113.57% at 93% 5.13%, #8DC63F 0%, #92B800 81.8%)",
              }}
              className="batch_2_tarif hero_section_item_4"
            >
              <h3 className="heading-4 text-white">All-In-One Marketplace</h3>
              <p className="text-white">
                Access A Wide Range Of Research And Audit Solutions Tailored For
                Your Business Growth And Success
              </p>
              <div
                style={{
                  background: "#8EC539",
                }}
                className="icon_box"
              >
                <svg
                  width="71"
                  height="70"
                  viewBox="0 0 71 70"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M50.3359 28.3335H38.3359V31.0002H50.3359V28.3335Z"
                    fill="#FAFAFA"
                  />
                  <path
                    d="M50.3359 39H38.3359V41.6667H50.3359V39Z"
                    fill="#FAFAFA"
                  />
                  <path
                    d="M50.3359 33.667H42.3359V36.3337H50.3359V33.667Z"
                    fill="#FAFAFA"
                  />
                  <path
                    d="M38.3346 35.0002L31.668 28.3335V33.6668H23.668V36.3335H31.668V41.6668L38.3346 35.0002Z"
                    fill="#FAFAFA"
                  />
                </svg>
              </div>
            </div>
          </Col>
        </Row>
        {modalShow && <TalktoExpertModal modalShow={modalShow} setModalShow={setModalShow}/>}
      </Container>
      <div
        style={{
          background: "linear-gradient(91deg, #007EC3 -0.09%, #003C5D 94.75%)",
          height: "80px",
        }}
        className="w-full line_hero_section_bottom"
      ></div>
    </div>
  );
};

export default HomeBannerNew;
