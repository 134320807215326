import React,{createContext,useState,useEffect} from 'react';
import Header from './Header';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import base_url from '../utils/config';
import HeaderNew from './header/HeaderNew';


export default function MainPage() {
   
    // useEffect(() => {
    //   document.title = "FloorWalk Market Place"   
    // }, []);

    return (
      <>
            {/* <Header /> */}
            <HeaderNew/>
            <Outlet />
            <Footer />
      </>
    )
}
