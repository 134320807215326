import "./custom.scss";
import "./index.css";
import React,{createContext,useState,useEffect} from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Provider } from "react-redux";
import store from "./utils/store";
import Error from './components/Error';
import HomePage from './components/HomePage';
import ExploreMarketPlace from './components/ExploreMarketPlace';
import IndividualProduct from './components/IndividualProduct';
import Login from './components/accountinfo/Login';
import Registration from './components/accountinfo/Registration';
import IndividualCategory from './components/IndividualCategory';
import IndividualCategorySpecial from './components/IndividualCategorySpecial';
import SolutionPage from './components/SolutionPage';
import IndividualSolutionPage from './components/IndividualSolutionPage';
import AuditProjectSetup1 from './components/AuditProjectSetup1';
import AuditProjectSetup2 from './components/AuditProjectSetup2';
import AuditProjectSetup3 from './components/AuditProjectSetup3';
import AuditProjectSetupFinal from './components/AuditProjectSetupFinal';
import StepProgressBar from './components/StepProgressBar';
import UserDashboardMain from './components/userdashboard/UserDashboardMain';
import UserDashboardBody from "./components/userdashboard/UserDashboardBody";
import Reports from "./components/userdashboard/dashReport/Reports";
import Projects from "./components/userdashboard/Projects";
import Stores from "./components/userdashboard/dashStores/Stores";
import MainPage from "./components/MainPage";
import ReportList from "./components/userdashboard/dashReport/ReportList";
import ReportListDetail from "./components/userdashboard/dashReport/ReportListDetail";
import DetailsProject from "./components/userdashboard/dashReport/DetailsProject";
import AccountInfoMain from "./components/accountinfo/AccountInfoMain";
import DashProfile from "./components/userdashboard/dashprofile/DashProfile";
import ForgotPassword from "./components/accountinfo/ForgotPassword";
import ResetPassword from "./components/accountinfo/ResetPassword";
import DashChangePassword from "./components/userdashboard/dashChangePassword/DashChangePassword";
import DashProjectSetup from "./components/userdashboard/dashProjectsetup/DashProjectSetup";
import Invoices from "./components/userdashboard/dashInvoice/Invoices";
import ReportStore from "./components/userdashboard/dashReport/StoreLists";
import StoreLists from "./components/userdashboard/dashReport/StoreLists";
import CaseStudyMain from "./components/sidePages/casestudy/CaseStudyMain";
import Manyavar from "./components/sidePages/casestudy/Manyavar";
import BlogsMain from "./components/sidePages/blogs/BlogsMain";
import CheckMarketGap from "./components/sidePages/blogs/CheckMarketGap";
import RetailersAspect from "./components/sidePages/blogs/RetailersAspect";
import StockAvailability from "./components/sidePages/blogs/StockAvailability";
import AuditStore from "./components/sidePages/blogs/AuditStore";
import GenuineReview from "./components/sidePages/blogs/GenuineReview";
import ConsumerInsight from "./components/sidePages/blogs/ConsumerInsight";
import Amazon from "./components/sidePages/casestudy/Amazon";
import Garmin from "./components/sidePages/casestudy/Garmin";
import Vajor from "./components/sidePages/casestudy/Vajor";
import JMC from "./components/sidePages/casestudy/JMC";
import Indyverse from "./components/sidePages/casestudy/Indyverse";
import Shott from "./components/sidePages/casestudy/Shott";
import Piaggio from "./components/sidePages/casestudy/Piaggio";
import TermsandCondition from "./components/sidePages/terms&condition/TermsandCondition";
import RefundPolicy from "./components/sidePages/refundPolicy/RefundPolicy";
import PrivacyPolicy from "./components/sidePages/privacyPolicy/PrivacyPolicy";
import FrequentyAsked from "./components/sidePages/faqs/FrequentyAsked";
import base_url from "./utils/config";
// import { clarity } from "react-microsoft-clarity";
import TagManager from "react-gtm-module";
import ContactUs from "./components/sidePages/contactus/ContactUs";
import PricingMain from "./components/sidePages/pricing/PricingMain";
import NewIndividualProduct from "./components/new_individual_product/NewIndividualProduct";

// clarity.init('jtcn9xgwfp');
const tagManagerArgs = {
  gtmId: 'GTM-PLKTVFCF'
};
TagManager.initialize(tagManagerArgs);

export const AppContext = createContext();
const userId = localStorage.getItem("user_id");
const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <MainPage />,
    errorElement: <Error />,

    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/casestudies",
        element: <CaseStudyMain />
      },
      {
        path: "/casestudies/manyavar",
        element: <Manyavar />
      },
      {
        path: "/casestudies/amazon",
        element: <Amazon />
      },
      {
        path: "/casestudies/garmin",
        element: <Garmin />
      },
      {
        path: "/casestudies/vajor",
        element: <Vajor />
      },
      {
        path: "/casestudies/jmc",
        element: <JMC />
      },
      {
        path: "/casestudies/indyverse",
        element: <Indyverse />
      },
      {
        path: "/casestudies/shott",
        element: <Shott />
      },
      {
        path: "/casestudies/piaggio",
        element: <Piaggio />
      },
      {
        path: "/blogs",
        element: <BlogsMain />
      },
      {
        path: "/terms_and_conditions",
        element: <TermsandCondition/>
      },
      {
        path: "/refund_policy",
        element: <RefundPolicy/>
      },
      {
        path: "/frequently_asked_question",
        element: <FrequentyAsked/>
      },
      {
        path: "/privacy_policy",
        element: <PrivacyPolicy/>
      },
      {
        path: "/contact_us",
        element: <ContactUs/>
      },
      {
        path: "/pricing",
        element: <PricingMain/>
      },
      {
        path: "/blogs/checkmarketgap",
        element: <CheckMarketGap />
      },
      {
        path: "/blogs/retailersaspect",
        element: <RetailersAspect />
      },
      {
        path: "/blogs/stockavailability",
        element: <StockAvailability />
      },
      {
        path: "/blogs/auditstore",
        element: <AuditStore />
      },
      {
        path: "/blogs/genuinereview",
        element: <GenuineReview />
      },
      {
        path: "/blogs/consumerinsight",
        element: <ConsumerInsight />
      },
      {
        path: "/exploremarketplace",
        element: <ExploreMarketPlace />,
      },
      // {
      //   path: "/:url_structure",
      //   element: <IndividualCategory />,
      // },
      {
        path:"/:url_structure",
        element: <NewIndividualProduct/>
      },
      {
        path: "/:url_structure/:url_structure",
        element: <IndividualProduct />,
      },
      {
        path: "/individualcategoryspecial/:id",
        element: <IndividualCategorySpecial />,
      },
      {
        path: "/solutionpage",
        element: <SolutionPage />,
      },
      {
        path: "/individualsolutionpage/:id",
        element: <IndividualSolutionPage />,
      },
      {
        path: "/auditprojectsetup1/:id",
        element: <AuditProjectSetup1 />
      },
      {
        path: "/auditprojectsetup2/:id",
        element: <AuditProjectSetup2 />
      },
      {
        path: "/auditprojectsetup3/:id",
        element: <AuditProjectSetup3 />
      },
      {
        path: "/auditprojectsetupfinal/:id",
        element: <AuditProjectSetupFinal />
      },
      {
        path: "/stepupprogressbar/:url_structure",
        // element : userId ? <StepProgressBar/> : <Error/>
        element: <StepProgressBar />
      },
    ]
  },
  {
    path: "/accountinfo",
    element: <AccountInfoMain />,
    errorElement: <Error />,
    children: [
      {
        path: "/accountinfo/login",
        element: <Login />,
      },
      {
        path: "/accountinfo/registration",
        element: <Registration />,
      },
      {
        path: "/accountinfo/forgotpassword",
        element: <ForgotPassword />,
      },
      {
        path: "/accountinfo/resetpassword",
        element: <ResetPassword />,
      },
    ]
  },
  {
    path: "/userdashboardmain",
    element: <UserDashboardMain />,
    errorElement: <Error />,
    children: [
      {
        path: "/userdashboardmain",
        element: <UserDashboardBody />
      },

      {
        path: "/userdashboardmain/reports",
        element: <Reports />,
      },
      {
        path: "/userdashboardmain/detailsproject",
        element: <DetailsProject />,
      },
      {
        path: "/userdashboardmain/reportslist",
        element: <ReportList />,
      },
      {
        path: "/userdashboardmain/reportstoreslist",
        element: <StoreLists />,
      },
      {
        path: "/userdashboardmain/reportslistdetail",
        element: <ReportListDetail />,
      },
      {
        path: "/userdashboardmain/projects",
        element: <Projects />,
      },
      {
        path: "/userdashboardmain/stores",
        element: <Stores />,
      },
      {
        path: "/userdashboardmain/invoices",
        element: <Invoices />,
      },
      {
        path: "/userdashboardmain/profile",
        element: <DashProfile />,
      },
      {
        path: "/userdashboardmain/changepassword",
        element: <DashChangePassword />,
      },
      {
        path: "/userdashboardmain/projectsetup",
        element: <DashProjectSetup />,
        children: [
          {
            path: "/userdashboardmain/projectsetup/stepupprogressbar/:url_structure",
            // element : userId ? <StepProgressBar/> : <Error/>
            element: <StepProgressBar />
          },
        ]
      },

    ]
  },
])
function App() {
  const [categoryDataMain, setCategoryDataMain] = useState([])
    const [popularSolutionDataMain,setPopularSolutionDataMain] = useState([]);
    const [productData,setProductData] = useState([]);
    useEffect(() => {
        getApiData("manager/category_public");
        getApiData("manager/solution_popular");
        getApiData(`manager/public_category/${19}/solution_detail`) // 1 = categoryId , in live it is 19
        localStorage.setItem("selectedCategoryId",19)
    }, []);
    const getApiData = async (url) => {
      try {
        const response = await fetch(base_url + url);
        if (response.ok) {
          const result = await response.json();
          if(url === "manager/category_public"){
            setCategoryDataMain(result)
          }else if(url === "manager/solution_popular"){
            setPopularSolutionDataMain(result)
          }else{
           setProductData(result)
          }
         
        } else {
          console.error('API request failed:', response.status, response.statusText);
        }
      } catch (error) {
        console.error('An error occurred while fetching the API:', error);
      }
    };
  return (
    <div>
      <Provider store={store}>
      <AppContext.Provider value={{categoryDataMain,setCategoryDataMain,popularSolutionDataMain,setPopularSolutionDataMain,productData,setProductData}}>
        <RouterProvider router={appRouter} />
        </AppContext.Provider>
      </Provider>
    </div>
  );
}

export default App;
