import { useRef,useState } from 'react';
import base_url from '../config';

export function useFetchinFunction() {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const token = localStorage.getItem("token");
    const activeRequest = useRef(false);
    const abortController = useRef(new AbortController());

    const getApiData = async (url, useToken = true) => {
        if (activeRequest.current) return;
        activeRequest.current = true;
        setIsLoading(true);

        try {
            const headers = {
                'Content-Type': 'application/json',
                ...(useToken && token && { 'Authorization': `Token ${token}` })
            };

            const response = await fetch(base_url + url, {
                method: 'GET',
                headers,
                signal: abortController.current.signal
            });

            if (response.ok) {
                const result = await response.json();
                setData(result);
            } else {
                console.error('API request failed:', response.status, response.statusText);
                setError(response.statusText);
            }
        } catch (error) {
            if (error.name !== 'AbortError') {
                console.error('An error occurred while fetching the API:', error);
                setError(error.message);
            }
        } finally {
            activeRequest.current = false;
            setIsLoading(false);
        }
    };

    const cancelRequest = () => {
        abortController.current.abort();
        abortController.current = new AbortController();  // Reset controller for future requests
    };

    return { data, setData, isLoading, getApiData, error, setIsLoading, cancelRequest };
}
