import { Container, Row, Col, Card } from 'react-bootstrap';
import '../../styles/homeretaildetail.css'
import {IconBadge} from "./IconBadge";

const HomeRetailDetail = () => {
  return (
    <section className="py-5 xl-py-10 bg-custom-secondary">
      <Container>
        <div className="text-center mb-5">
          <h2 className="text-white display-4 fw-bold mb-3" style={{fontSize:"2.5rem"}}>
            Retail Audits & its Benefits
          </h2>
          <p className="text-white">
            Businesses turn to Mystery shopping services because these services
            offer a number of benefits for companies. Many companies report that
            these services help them to:
          </p>
        </div>

        <Row className="g-3">
          <Col lg={8}>
            <Row className="g-3">
              <Col xs={12} lg={7}>
                <Card className="w-100 h-100 rounded-10 custom-card">
                  <Card.Body>
                    <h2 className="lh-sm fs-4 fs-lg-4 fw-bold" style={{width:"70%",color:"#353e4c"}}>Gather evidence of trouble</h2>
                    <p className="fw-semibold" style={{fontSize:"13px",color:"#9ca3af"}}>
                    Mystery Shopping India by FloorWalk helps in catching possible trouble as well. In some cases, employee dishonesty can require action. Even when employees are not performing, though, some employers fear taking action because they fear wrongful dismissal lawsuits. In other cases, employers suspect that crime is taking place but have no evidence. Secret shopper services can help employers get the evidence they need to act.
                    </p>
                  </Card.Body>
                  <IconBadge icon="icon1" />
                </Card>
              </Col>
              <Col xs={12} lg={5}>
                <Card className="h-100 rounded-10 custom-card">
                  <Card.Body>
                  <h2 className="lh-sm fs-4 fs-lg-4 fw-bold" style={{color:"#353e4c",width:"80%"}}>Answer employer questions</h2>
                    <p className="fw-semibold" style={{fontSize:"13px",color:"#9ca3af"}}>
                    All employers wonder what really happens when they are not around. Virtual customers are the employers eyes and ears and can reveal problems that the business owner may be the last to know.
                    </p>
                  </Card.Body>
                  <IconBadge icon="icon2" />
                </Card>
              </Col>
              <Col xs={12} lg={7}>
                <Card className="w-100  rounded-10 custom-card" style={{height:"312px"}}>
                  <Card.Body className='mb-5'>
                    <h2 className="lh-sm fs-4 fs-lg-4 fw-bold" style={{width:"70%",color:"#353e4c"}}>Get customer insight</h2>
                    <p className="fw-semibold" style={{fontSize:"13px",color:"#9ca3af"}}>
                    Most business owners would love to be able to have direct, unbiased, full information from each customer what is working, what needs work in a company, what would make shoppers buy? Secret shopper services offer business owners the chance to ask these sorts of questions and get answers.
                    </p>
                  </Card.Body>
                  <img src='/icons/line2.svg'
                   style={{position:"absolute",left:"0",right:"0",bottom:"0"}} 
                   width={"100%"}/>
                  <IconBadge icon="icon3" />
                </Card>
              </Col>
              <Col xs={12} lg={5}>
                <Card className="h-100 rounded-10 custom-card">
                  <Card.Body>
                  <h2 className="lh-sm fs-4 fs-lg-4 fw-bold" style={{color:"#353e4c",width:"80%"}}>Locate potential business Hazards</h2>
                    <p className="fw-semibold" style={{fontSize:"13px",color:"#9ca3af"}}>
                    Employee fraud, theft, and dishonesty can all mean lost business and even potential lawsuits. Mystery shopper services uncover these potential problems so that business owners can act on them before they bankrupt a company.
                    </p>
                  </Card.Body>
                  <IconBadge icon="icon4" />
                </Card>
              </Col>
              <Col xs={12} lg={5}>
                <Card className="h-100 rounded-10 custom-card">
                  <Card.Body>
                  <h2 className="lh-sm fs-4 fs-lg-4 fw-bold" style={{color:"#353e4c",width:"80%"}}>Stop criminal activity</h2>
                    <p className="fw-semibold" style={{fontSize:"13px",color:"#9ca3af"}}>
                    Selling controlled substances to minors, stealing, or selling illegal services and products: These are all fears of most employers. If employees are doing something illegal at work, employers as well as the company may be held liable. Mystery shopping helps employers detect and stop this sort of activity before it threatens a business.
                    </p>
                  </Card.Body>
                  <IconBadge icon="icon5" />
                </Card>
              </Col>
              <Col xs={12} lg={7}>
                <Card className="w-100 h-100 rounded-10 custom-card">
                  <Card.Body>
                    <h2 className="lh-sm fs-4 fs-lg-4 fw-bold" style={{width:"70%",color:"#353e4c"}}>Help with decision making</h2>
                    <p className="fw-semibold" style={{fontSize:"13px",color:"#9ca3af"}}>
                    Employers need to have all the facts before they make hiring, promotion, and business plan decisions. By revealing what works and what does not from a customers perspective, mystery shopper services help business owners make these decisions with all the facts in place.
                    </p>
                  </Card.Body>
                  <IconBadge icon="icon6" />
                </Card>
              </Col>
            </Row>
          </Col>
          <Col lg={4}>
            <Row className="g-4">
              <Col xs={12}>
                <Card className="w-100 h-100 rounded-10 custom-card" style={{paddingBottom:"0rem"}}>
                  <Card.Body>
                    <h2 className="lh-sm fs-4 fs-lg-4 fw-bold" style={{width:"75%",color:"#353e4c"}}>Improve company image And branding</h2>
                    <p className="fw-semibold" style={{fontSize:"13px",color:"#9ca3af"}}>
                    Secret shoppers not only come into the store to see how company employees provide service in person; quality companies can also phone or email a business at various times of the day to determine how good telephone and computer service is. If customers are kept waiting on the telephone or emails are ignored, a company's reputation may suffer.Performance audits reveal these problems so that business owners can take appropriate action.
                    </p>
                    <div className="svg-container">
                      <svg width="134" height="108" viewBox="0 0 134 108" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2589_1681)">
<path d="M104.222 59.5552V0H29.7776V29.7776H0V133.999H59.5552V104.222H74.444V133.999H133.999V59.5552H104.222ZM29.7776 119.11H14.8888V104.222H29.7776V119.11ZM29.7776 89.3328H14.8888V74.444H29.7776V89.3328ZM29.7776 59.5552H14.8888V44.6664H29.7776V59.5552ZM59.5552 89.3328H44.6664V74.444H59.5552V89.3328ZM59.5552 59.5552H44.6664V44.6664H59.5552V59.5552ZM59.5552 29.7776H44.6664V14.8888H59.5552V29.7776ZM89.3328 89.3328H74.444V74.444H89.3328V89.3328ZM89.3328 59.5552H74.444V44.6664H89.3328V59.5552ZM89.3328 29.7776H74.444V14.8888H89.3328V29.7776ZM119.11 119.11H104.222V104.222H119.11V119.11ZM119.11 89.3328H104.222V74.444H119.11V89.3328Z" fill="#BEDCEC"/>
</g>
<defs>
<clipPath id="clip0_2589_1681">
<rect width="133.999" height="108" fill="white"/>
</clipPath>
</defs>
</svg>
<svg width="134" height="108" viewBox="0 0 134 108" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2589_1683)">
<path d="M29.7776 59.5552V0H104.222V29.7776H133.999V133.999H74.444V104.222H59.5552V133.999H0V59.5552H29.7776ZM104.222 119.11H119.11V104.222H104.222V119.11ZM104.222 89.3328H119.11V74.444H104.222V89.3328ZM104.222 59.5552H119.11V44.6664H104.222V59.5552ZM74.444 89.3328H89.3328V74.444H74.444V89.3328ZM74.444 59.5552H89.3328V44.6664H74.444V59.5552ZM74.444 29.7776H89.3328V14.8888H74.444V29.7776ZM44.6664 89.3328H59.5552V74.444H44.6664V89.3328ZM44.6664 59.5552H59.5552V44.6664H44.6664V59.5552ZM44.6664 29.7776H59.5552V14.8888H44.6664V29.7776ZM14.8888 119.11H29.7776V104.222H14.8888V119.11ZM14.8888 89.3328H29.7776V74.444H14.8888V89.3328Z" fill="#BEDCEC"/>
</g>
<defs>
<clipPath id="clip0_2589_1683">
<rect width="133.999" height="108" fill="white" transform="matrix(-1 0 0 1 133.999 0)"/>
</clipPath>
</defs>
</svg>

                    </div>
                  </Card.Body>
                  <IconBadge icon="icon7" />
                </Card>
              </Col>

              {/* Right Side Card 2 */}
              <Col xs={12}>
                <Card className="w-100 h-100 rounded-10 custom-card">
                  <Card.Body>
                    <h2 className="lh-sm fs-4 fs-lg-4 fw-bold" style={{width:"75%",color:"#353e4c"}}>Increase sales</h2>
                    <p className="fw-semibold" style={{fontSize:"13px",color:"#9ca3af"}}>
                    Whether a business is service- or product-based, most companies are interested in selling something to someone. Businesses spend considerable effort and money on marketing, but if there is a flaw somewhere in the selling process, customers may be choosing to make purchases elsewhere.Performance audits help businesses to uncover these problems in the selling process so that marketing and sales processes work seamlessly to create more sales and profits.
                    </p>
                  
                    <div style={{display:"flex",justifyContent:"flex-end",paddingBottom:"2rem",paddingTop:"2rem"}}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={66}
                  height={76}
                  viewBox="0 0 66 76"
                  fill="none"
                >
                  <path
                    d="M1.20889 23.7157L1.4646 44.1689L32.9967 21.0453L65.097 43.3734L64.8413 22.9201L32.741 0.592051L1.20889 23.7157Z"
                    fill="#007DC1"
                    fillOpacity="0.24"
                  />
                  <path
                    d="M1.6066 55.5321L1.8623 75.9854L33.3944 52.8617L65.4947 75.1898L65.239 54.7365L33.1387 32.4085L1.6066 55.5321Z"
                    fill="#007DC1"
                    fillOpacity="0.24"
                  />
                </svg>
              </div>
                  </Card.Body>
                  <IconBadge icon="icon8" />
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="g-3 mt-2">
        <Col xs={12} lg={7}>
                <Card className="h-100 rounded-10 custom-card">
                  <Card.Body>
                  <h2 className="lh-sm fs-4 fs-lg-4 fw-bold" style={{width:"75%",color:"#353e4c"}}>Manage a business</h2>
                    <p className="fw-semibold" style={{fontSize:"13px",color:"#9ca3af"}}>
                    Most business owners are busy, and keeping track of everything is difficult. Secret shopping services offer owners an additional set of useful, impartial information that can be used in management decisions and changes.
                    </p>
                  </Card.Body>
                  <IconBadge icon="icon9" />
                </Card>
              </Col>
              <Col xs={12} lg={5}>
                <Card className="h-100 rounded-10 custom-card">
                  <Card.Body>
                  <h2 className="lh-sm fs-4 fs-lg-4 fw-bold" style={{width:"75%",color:"#353e4c"}}>Improve quality of service</h2>
                    <p className="fw-semibold" style={{fontSize:"13px",color:"#9ca3af"}}>
                    Every business wants to be known for a high level of service, and many companies even spend considerable time and money training employees to provide
                    </p>
                  </Card.Body>
                  <IconBadge icon="icon10" />
                </Card>
              </Col>
        </Row>
      </Container>
    </section>
  );
};


export default HomeRetailDetail;