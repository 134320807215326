import React, { useEffect, useState } from 'react';
import html2pdf from 'html2pdf.js';
import { ToWords } from 'to-words';
import { useFetchAuth } from '../../../utils/hooks/useFetchAuth';
import floorwalklogo from "../../../images/floorwalk_logo.png";
import base_url from '../../../utils/config';

const InvoiceSlip = ({ id }) => {
    const token = localStorage.getItem("token");

    const toWords = new ToWords({
        localeCode: 'en-IN',
        converterOptions: {
            currency: true,
            ignoreDecimal: false,
            ignoreZeroCurrency: false,
            doNotAddOnly: false,
            currencyOptions: { // can be used to override defaults for the selected locale
                name: 'Rupee',
                plural: 'Rupees',
                symbol: '₹',
                fractionalUnit: {
                    name: 'Paisa',
                    plural: 'Paise',
                    symbol: '',
                },
            }
        }
    });

    const toWordsUsd = new ToWords({
        localeCode: 'en-US',
        converterOptions: {
            currency: true,
            ignoreDecimal: false,
            ignoreZeroCurrency: false,
            doNotAddOnly: false,
            currencyOptions: { // can be used to override defaults for the selected locale
                name: 'Dollar',
                plural: 'Dollars',
                symbol: '$',
                fractionalUnit: {
                    name: 'Cent',
                        plural: 'Cents',
                        symbol: '',
                },
            }
        }
    });

    const getApiData = async (url) => {
        try {
            const headers = {
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json'
            };

            const options = {
                method: 'GET',
                headers: headers
            };

            const response = await fetch(base_url + url, options);

            if (response.ok) {
                const result = await response.json();
                return result;
            } else {
                console.error('API request failed:', response.status, response.statusText);
            }
        } catch (error) {
            console.error('An error occurred while fetching the API:', error);
        }
    };

    const formatDate = (timestamp) => {
        const dateObj = new Date(timestamp);
        const day = dateObj.getDate();
        const month = dateObj.getMonth() + 1;
        const year = dateObj.getFullYear();
        return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
    };

    const handleDownload =  async () => {
        const data = await getApiData(`client/mp/invoice/${id}`);
     if(data){
        const formattedDate = formatDate(data?.invoice_data?.transaction?.payment_success_date);
        let payInWords;
        let finalPrice;
        let currencySymbol;
        if(data?.invoice_data?.order_data?.country_code === "IN"){
          payInWords =  toWords.convert(((data?.invoice_data?.order_data?.price)) + ((data?.invoice_data?.order_data?.price * data?.invoice_data?.order_data?.solution?.tax?.rate)/100), {currency: true})
            finalPrice = data?.invoice_data?.order_data?.solution?.price_INR;
            currencySymbol = '₹';
        } else{
            payInWords =  toWordsUsd.convert(((data?.invoice_data?.order_data?.price)) + ((data?.invoice_data?.order_data?.price * data?.invoice_data?.order_data?.solution?.tax?.rate)/100), {currency: true})
            finalPrice = data?.invoice_data?.order_data?.solution?.price_USD;
            currencySymbol = '$'
        }        const htmlContent = `
    <!DOCTYPE html>
    <html lang="en">
    
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Document</title>
        <style>
            table {
                border-collapse: collapse;
                width: 100%;
            }
    
            th,
            td {
                border-bottom: 1px solid #000;
                padding: 8px;
                text-align: left;
            }
    
            th {
                background-color: black;
                color: white;
            }
          
        </style>
    </head>
    
    <body >
        <div style="padding: 2rem;padding-bottom:"1rem">
            <div style="display: flex;justify-content: space-between;">
                <div>
                <img src="${floorwalklogo}" alt="Floorwalk Logo">
                </div>
                <div>
                    <h1>TAX INVOICE</h1>
                    <P><b>${data?.invoice_data?.Invoice_number}</b></P>
                </div>
            </div>
            <div>
                <h5><b>FLOORWALK CONSULTANTS PVT. LTD.</b></h5>
                <P>CIN U74140HR2014PTC052945</P>
                <P>L17, A/5, 3RD FLOOR,</P>
                <P>DLF phase 2</P>
                <p>Gurugram Haryana 122011</p>
                <p>India</p>
                <p>GSTIN <b>: 06AACCF4323N1ZM</b></p>
            </div>
            <div style="margin-top:2rem;">
                <p>Bill To</p>
                <h5><b> ${data?.invoice_data?.order_data?.user?.email} </b></h5>
                <h5>${data?.invoice_data?.client_profile_data?.company_name ? data?.invoice_data?.client_profile_data?.company_name : ""}</h5>
                <P>${data?.invoice_data?.client_profile_data?.address ? data?.invoice_data?.client_profile_data?.address : ""} </P>
            </div>
            <div style="display: flex;justify-content: flex-end;">
                <div>
                    <p>Invoice Date : <span>${formattedDate}</span></p>
                </div>
            </div>
            <p>Place of Supply: Haryana (06)</p>
            <table style="width: 100%;border-collapse: collapse;">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Item & Description</th>
                        <th>Qty</th>
                        <th>Rate</th>
                        <th>GST</th>
                        <th>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td>${data?.invoice_data?.order_data?.solution?.name}</td>
                        <td>${data?.invoice_data?.order_data?.no_of_response}</td>
                        <td>${finalPrice}.00</td>
                        <td>${data?.invoice_data?.order_data?.solution?.tax?.rate} %</td>
                        <td>${(data?.invoice_data?.order_data?.price)}.00</td>
                    </tr>
                </tbody>
            </table>
        <div style="display: flex;justify-content: flex-end;padding-right: 2rem;margin-top:1rem;">
              
            <div style="width: 270px;">
                <div style=" display: flex;
                justify-content: space-between;margin-top:.5rem;">
                    <p>Sub Total:</p>
                    <p>${(data?.invoice_data?.order_data?.price)}.00</p>
                </div>
                <div style=" display: flex;
                justify-content: space-between;margin-top:.5rem;">
                    <p>GST(${data?.invoice_data?.order_data?.solution?.tax?.rate} %):</p>
                    <p>${(data?.invoice_data?.order_data?.price * data?.invoice_data?.order_data?.solution?.tax?.rate)/100}</p>
            </div>
                <div style=" display: flex;
                justify-content: space-between;margin-top:.5rem;">
                    <p><b>Total:</b></p>
                    <p><b>${currencySymbol}${((data?.invoice_data?.order_data?.price)) + ((data?.invoice_data?.order_data?.price * data?.invoice_data?.order_data?.solution?.tax?.rate)/100)}.00</b></p>
                </div>
                <div style=" display: flex;
                justify-content: space-between;margin-top:.5rem;">
                    <p>Payment Made:</p>
                    <p style="color: red;">(-) ${((data?.invoice_data?.order_data?.price)) + ((data?.invoice_data?.order_data?.price * data?.invoice_data?.order_data?.solution?.tax?.rate)/100)}.00</p>
                </div>
                <div style=" display: flex;
                justify-content: space-between;font-weight: bold;background-color: rgb(248, 248, 248);padding: .2rem;margin-top:.5rem;" >
                    <p>Balance Due:</p>
                    <p>${currencySymbol}0.00</p>
                </div>
                <div style=" display: flex;
                justify-content: center; gap: 2.5rem;margin-top:.5rem;" >
                    <p style="white-space: nowrap;">Total In Words:</p>
                    <p style = "font-size: .9rem; "><b>${payInWords}</b></p>
                </div>
            </div>
            </div>
       <div  style="margin-top: 10rem;">
        <p style="font-size: .9rem;">Thank you for your business</p>
        <p><b>FLOORWALK CONSULTANTS PVT. LTD.</b></p>
       </div>
        </div>
    </body>
    
    </html>
    `;

        const options = {
            margin: 10,
            filename: `${data?.invoice_data?.Invoice_number}`,
            image: { type: 'png', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        };

        const pdfGenerator = html2pdf();

        // Create the PDF
        // pdfGenerator.set(options).from(htmlContent).outputPdf().then((pdf) => {
        //   // pdf.save();
        // });
        //  
        html2pdf().set(options).from(htmlContent).save();
    }
    };

    return (
            <button onClick={handleDownload} className='button create_ac_btn' style={{padding:".5rem .8rem"}}><i className="fa-sharp fa-solid fa-download"></i></button>
    );
};

export default InvoiceSlip;
