import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'authData',
  initialState: {
    userId : null
  },
  reducers: {
    setAuthData: (state, action) => {
      state.userId = action.payload
    },
    removeAuthData : (state) => {
        state.userId = null
    }
  },
});

export const { setAuthData, removeAuthData } = authSlice.actions;
export default authSlice.reducer;