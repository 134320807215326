import React ,{useEffect}from "react";
import { Col, Container, Row } from "react-bootstrap";
import CountrySelect from "./CountrySelect";
import PricingSection from "./PricingSection";
import "../../../styles/pricing.css"
import { useFetch } from "../../../utils/hooks/useFetch";
import base_url from "../../../utils/config";
import Shimmer from "../../shimmerComponents/Shimmer"

const PricingMain = () => {
  const categoryId = localStorage.getItem("selectedCategoryId");
  // const categoryId = 19; // for live
  const {data,setData, loading} = useFetch(base_url + "manager/public_category/" + categoryId + "/solution_detail")
 useEffect(()=>{
     window.scrollTo({ top: 0, left: 0, behavior: "instant" });
   },[])
  return (
    <Container className="py-5">
      <div className="d-flex flex-column mx-auto gap-y-6">
        <h2 className="text-center heading-1 section-title">
          Choose the{" "}
          <span style={{ color: "#8DC63F" }}>
            Perfect Competition Benchmarking Package{" "}
          </span>
          for Your <span style={{ color: "#8DC63F" }}>Business</span>
        </h2>
        <p
          className="text-center pera mx-auto"
          style={{ fontFamily: '"Roboto", sans-serif', maxWidth: "798px" }}
        >
          Tailor your insights with our flexible plans designed to help you stay
          ahead in 10 major metro cities. Select the package that best fits your
          business needs and unlock in-depth competitor analysis to drive growth
          and outperform the competition.
        </p>
      </div>
      <div
        style={{
          background: "#FAFAFA",
          padding: "40px 0px",
          marginTop: "20px",
        }}
      >
         {loading ? <Shimmer/>:
        <div className="pricing_content_wrapper position-relative">
        <PricingSection data={data[0]?.solutions} />
        </div>}
      </div>
    </Container>
  );
};

export default PricingMain;
