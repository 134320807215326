import { Container } from "react-bootstrap";
import ReadingCard from "./RecommendedCard";
import shotcard from "../../images/shotcard.jpg";
import indicard from "../../images/indicard.jpg";
import cardimg from "../../images/card.png";
import "../../styles/pricing.css";

const RecommendedReading = () => {
  return (
    <Container className="py-5">
      <div className="flex-column d-flex ">
        <h2 className="text-center section-title">Recommended Reading</h2>
      </div>
      <div className="recommend_reading_container">
        <ReadingCard
          image={cardimg}
          logo={cardimg}
          title="Amazon"
          description="The client wanted to study the Market price of the various television models. This was mainly to understand price gaps in the online..."
          buttonText="Learn More"
          onButtonClick={() => console.log("")}
          linkto="/casestudies/amazon"
        />
        <ReadingCard
          image={indicard}
          logo={indicard}
          title="Indyverse"
          description="The challenge at hand was to bolster sales by leveraging customer reviews and ratings as a means to enhance brand visibility in the target market."
          buttonText="Learn More"
          onButtonClick={() => console.log("")}
          linkto="/casestudies/indyverse"
        />
        <ReadingCard
          image={shotcard}
          logo={shotcard}
          title="Shott"
          description="The challenge at hand was to ensure that the product presentation within the company's retail outlets aligns with established standards..."
          buttonText="Learn More"
          onButtonClick={() => console.log("")}
          linkto="/casestudies/shott"
        />
      </div>
    </Container>
  );
};

export default RecommendedReading;
