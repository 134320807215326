import { Container, Stack } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addItem } from "../../utils/cartSlice";
import { useState } from "react";
import { postSaveForLaterData } from "../../utils/services/PostdataHandle";
import { useLocation, useNavigate } from "react-router-dom";
import { Jodit } from "jodit-react";
import AuthModal from "../AuthModal";

const ProductUpperPart = ({data}) => {
  const navigate = useNavigate();
  const location = useLocation()
  const id = location.state?.id;
   const dispatch = useDispatch();
   const cartItems = useSelector((store) => store.cart.items);
    const cartItemsfordraft = useSelector((store) => store.cart);
    const storedInputs = useSelector((store) => store.orderdata.firstPage.inputs);
     const selectedFiles = useSelector((store) => store.orderdata.firstPage.selectedFile)
   const existingUser = (localStorage.getItem('user_id'));
     const [modalShow, setModalShow] = useState(false);
     const [loading, setLoading] = useState(false);
     const propsToExclude = ['describe', 'preference', 'selectedFile'];
     const alignment_factors = excludeProperties(storedInputs, propsToExclude);
     const selectedCategoryId = (localStorage.getItem('selectedCategoryId'))
     const fileData = selectedFiles.length > 0
     ? {
       file_name: selectedFiles[0].fileName,
       file_size: selectedFiles[0].fileSize,
       file_type: selectedFiles[0].fileType,
     }
     : {};
     const auditcountReduxwithId = useSelector((store) => store.orderdata.secondPage.totalAuditsPerStoreWithStoreId);
     const modifiedauditcountwithId = Object.entries(auditcountReduxwithId).map(([storeId, count]) => {
      if (count !== "") {
        return { store_id: parseInt(storeId), count: parseInt(count) };
      }
      return null;
    }).filter(item => item !== null);
     function excludeProperties(obj, propsToExclude) {
      const newObj = {};
      for (const key in obj) {
        if (!propsToExclude.includes(key)) {
          newObj[key] = obj[key];
        }
      }
      return newObj;
    }

  const handleAddItem = (item) => {
      let country_code = localStorage.getItem("selectedCountry") ? localStorage.getItem("selectedCountry") : "IN";
          if (!existingUser) {
            setModalShow(
              true,
            )
          }
          else {
            setLoading(true);
            const requestData = {
              // no_of_response: cartItemsfordraft.totalQuantity,
              no_of_response: 1,
              // solution: cartItemsfordraft.items[0]?.id,
              solution: id,
              describe: storedInputs.describe,
              file: fileData,
              user: +existingUser,
              status: "DRAFT",
              alignment_factors: alignment_factors,
              store: modifiedauditcountwithId,
              category: +selectedCategoryId,
              // category: 19, // live
              country_code: country_code,
            };
      
            const promise = postSaveForLaterData(requestData, "client/order_mp");
            promise.then(response => response.json())
              .then(data => {
                localStorage.setItem("orderId", data?.id);
                if (!data.detail) {
                  dispatch(addItem(item));
                  if(id){
                    navigate(`/stepupprogressbar/${item?.url_structure}`, { state: id });
                  }
                }
                else {
                  alert("Something went wrong, please refresh")
                }
      
              })
              .catch(error => {
                console.error('Error:', error);
              }).finally(() => {
                setLoading(false);
              })
            // navigate(`/stepupprogressbar/${url_structure}`, { state: id });
          }
    };
    
  return (
    <Container className="py-4 py-lg-5">
      <div
        style={{
          maxWidth: "1000px",
        }}
        className="d-flex flex-column mx-auto gap-y-6"
      >
        <h2 className="text-center heading-1 section-title" style={{color:"#007dc1"}}>
          {data?.name}
        </h2>
        <p
          className="text-center pera"
          style={{ fontFamily: '"Roboto", sans-serif' }}
        >
          {(data?.execution_time || '').replace(/<[^>]+>/g, '')}
          {/* {Jodit.modules.Helpers.stripTags(data?.execution_time)} */}
        </p>
        <p
          className="text-center pera"
          style={{ fontFamily: '"Roboto", sans-serif' }}
        >
          Affordable and reliable for all small businesses.
        </p>
        <div className="d-flex flex-wrap gap-2 align-items-center justify-content-center">
          <p className="price_text ">
            INR. {data?.price_INR} <span></span>
          </p>
          <p className="text-16">+ {data?.tax?.rate}% GST</p>
        </div>
        <Stack className="justify-content-center gap-3 gap-lg-4 flex-sm-row">
          <button className="button button-primary" onClick={() => handleAddItem(data)} disabled={loading}>{loading? "Processing..." : "Buy Now"}</button>
        </Stack>
      </div>
        <AuthModal
                        modalShow={modalShow}
                        setModalShow={setModalShow}
                        url_structure={data?.url_structure}
                        id={id}
                      />
    </Container>
  );
};

export default ProductUpperPart