import React, { useState } from 'react';
import { Col, Row, Container,Alert } from 'react-bootstrap';
import { useNavigate, Link} from 'react-router-dom';
import { postChangePasswordDashboard } from '../../../utils/services/PostdataHandle';
import "../../../styles/dashreport.css";

export default function DashChangePassword() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        oldpassword: '',
        newpassword: '',
        confirmpassword: '',
    });
    const [errorMessages, setErrorMessages] = useState({
        oldpasswordError: '',
        newpasswordError: '',
        confirmpasswordError: '',
    });
    const [errorMessage, setErrorMessage] = useState("");
    const [matchErrorMessage, setMatchErrorMessage] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        setErrorMessages(prevErrors => ({
            ...prevErrors,
            [`${name}Error`]: ''
        }));
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!formData.oldpassword) {
            setErrorMessages(prevErrors => ({
                ...prevErrors,
                oldpasswordError: "Old Password can't be empty"
            }));
            setHasError(true)
        }
        if (!formData.newpassword) {
            setErrorMessages(prevErrors => ({
                ...prevErrors,
                newpasswordError: "New Password can't be empty"
            }));
            setHasError(true)
        }
        if (!formData.confirmpassword) {
            setErrorMessages(prevErrors => ({
                ...prevErrors,
                confirmpasswordError: "New Password can't be empty"
            }));
            setHasError(true)
        }
        if (formData.newpassword !== formData.confirmpassword) {
            setMatchErrorMessage(true);
            setTimeout(()=>{
                setMatchErrorMessage(false);
              },1700)
        }

        if (formData.oldpassword && formData.newpassword && formData.confirmpassword && formData.newpassword === formData.confirmpassword) {
            const requestData = {
               old_password: formData.oldpassword,
               new_password: formData.newpassword
              };
              const promise = postChangePasswordDashboard(requestData,"client/market_place/change_password_api");
                promise.then(response => response.json())
                .then(data => {
                  setHasError(false);
                  setShowAlert(true);
                  setTimeout(()=>{
                    setShowAlert(false);
                  },1700)
                  setErrorMessage(data);
                
                  setFormData({
                    oldpassword: '',
                    newpassword: '',
                    confirmpassword: '',
                  });
                })
                .catch(error => {
                  console.error('Error:', error);
                });
        }
    }
    return (
        <>
        {showAlert && errorMessage.detail && (<Alert variant= "success" onClose={() => setShowAlert(false)} dismissible style={{ textAlign: "center" }}>
          {errorMessage.detail}
        </Alert>) }
          {showAlert && errorMessage.non_field_errors && errorMessage.non_field_errors.map((error, index) => (
            (<Alert variant= "danger" onClose={() => setShowAlert(false)} dismissible style={{ textAlign: "center" }} key={index}>
                {error}
        </Alert>)))
        }
            <div className="login_logo">
                <h6><b>Give us your details </b></h6>
            </div>
            <section className="login-area">
                <Container>
                    <form className="form" onSubmit={handleSubmit}>
                        <Row>
                            <Col md="12" className="d-flex justify-content-center">
                                <div className="login-box changepasswrs_loginbox" >
                                    <p className="login_form_control">
                                        <label htmlFor="login_password"><b>Old Password</b><span>*</span></label>
                                        <input
                                            type="password"
                                            placeholder="*******"
                                            name='oldpassword'
                                            value={formData.oldpassword}
                                            onChange={handleChange}
                                            className={hasError ? "login_input error_border" : "login_input success_border"}
                                        />
                                        {errorMessages.oldpasswordError && (
                                            <span className="error_message">{errorMessages.oldpasswordError}</span>
                                        )}
                                    </p>
                                    <p className="login_form_control">
                                        <label htmlFor="login_password"><b>New Password</b><span>*</span></label>
                                        <input
                                            type="password"
                                            placeholder="*******"
                                            name='newpassword'
                                            value={formData.newpassword}
                                            onChange={handleChange}
                                            className={hasError ? "login_input error_border" : "login_input success_border"}
                                        />
                                        {errorMessages.newpasswordError && (
                                            <span className="error_message">{errorMessages.newpasswordError}</span>
                                        )}
                                    </p>
                                    <p className="login_form_control">
                                        <label htmlFor="login_password"><b>Confirm Password</b><span>*</span></label>
                                        <input
                                            type="password"
                                            placeholder="*******"
                                            name='confirmpassword'
                                            value={formData.confirmpassword}
                                            onChange={handleChange}
                                            className={hasError ? "login_input error_border" : "login_input success_border"}
                                        />
                                        {errorMessages.confirmpasswordError && (
                                            <span className="error_message">{errorMessages.confirmpasswordError}</span>
                                        )}
                                    </p>
                                    {matchErrorMessage && <span style={{color:"#ff6f6f",fontSize:".9rem"}}>New Password and Confirm Password doesn't match</span>}
                                    <div className="login_btn_container">
                                        <button
                                            className="button mb-4 mt-3 btn-md text-center btn-size create_ac_btn"
                                        >
                                            Change Password
                                        </button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </form>
                </Container>
            </section>
        </>
    )
}
