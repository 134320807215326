import React,{useEffect} from 'react'
import HomeBanner from './HomeBanner'
import HowitWorks from './HowitWorks'
import HomeSolution from './HomeSolution'
import HomeBrand from './HomeBrand'
import base_url from '../utils/config'
import HomeBannerNew from './homepages/HomeBannerNew'
import HomeRetailDetail from './homepages/HomeRetailDetail'
import HowItWorks from './homepages/HowItWorks'
import HomeProducts from "./homepages/HomeProducts"
import RecommendedReading from "./homepages/RecommendedReading"
import HomeGlobalPartner from './homepages/HomeGlobalPartner'

export default function HomePage() {
	useEffect(()=>{
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
		document.title = "FloorWalk Market Place"
		// handleSubmit()
    },[]);

	// function handleSubmit() {
		
	// 	const requestData = {
	// 		username: "b@gmail.com",
	// 		password: 12345678,
	// 		phone: 9876544321,
	// 	};
	// 		fetch(base_url + "auditor/app/signup_api_app", {
	// 		  method: 'POST',
	// 		  headers: {
	// 			'Content-Type': 'application/json'
	// 		  },
	// 		  body: JSON.stringify(requestData)
	// 		})
	// 		// .then(response => response.json())
	// 		.then(response => {
	// 		  if (!response.ok) {
	// 			// throw new Error(HTTP error! Status: ${response.status});
	// 		  }
	// 		  return response.json();
	// 		})
	// 		.then(data => {
	// 		})
	// 		.catch(error => {
	// 		  console.error("API Request Error:", error);
	// 		})
	// }
	// function handleSubmit() {
	// 	const formData = new FormData();
	// 	formData.append('username', 'bnn94@gmail.com');
	// 	formData.append('phone', '9874541321');
	// 	formData.append('password', '12345678');
	
	// 	fetch(base_url + "auditor/app/signup_api_app", {
	// 		method: 'POST',
	// 		body: formData
	// 	})
	// 	.then(response => {
	// 		if (!response.ok) {
	// 			throw new Error(`HTTP error! Status: ${response.status}`);
	// 		}
	// 		return response.json();
	// 	})
	// 	.then(data => {
	// 	})
	// 	.catch(error => {
	// 		console.error("API Request Error:", error);
	// 	});
	// }
	
	
  return (
	<div>
	  {/* <HomeBanner/> */}
	  <HomeBannerNew/>
	  {/* <HowitWorks/> */}
	  <HomeGlobalPartner/>
	  <HowItWorks/>
	  {/* <HomeSolution/> */}
	  <HomeProducts/>
	  <HomeRetailDetail/>
	  {/* <HomeBrand/> */}
	  <RecommendedReading/>
	</div>
  )
}
