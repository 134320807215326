import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function StockAvailability() {
    const [modalShow, setModalShow] = useState(false);
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, []);
    return (
        <>
            <div className='categoryname_banner indiv_blog_heading' style={{ height: "120px" }}>
                <h1>Discrepancies in Stock Availability at Your Retail Stores</h1>
            </div>
            <div className='blog_content_box'>
                <p>As a retail/sales manager, one of my primary responsibilities is to ensure that our products are consistently available on the shelves of our partner retailers. The on-shelf availability of our products is crucial for maintaining our brand's presence and meeting the needs of our target consumers. To achieve this, companies rely on mystery shopping and auditing companies that deploy field agents to monitor and report on the adherence of retailers to product stocking and placement agreements. This approach allows us to ensure our products are consistently visible to target consumers, ultimately driving sales and brand visibility.

                </p>
                <p><b>The Challenge of On-Shelf Availability:</b></p>
                <p>Maintaining consistent on-shelf availability is a common challenge in the retail industry. Retailers often handle numerous product categories from various suppliers, and managing the stocking and placement of products can be a complex task. As a result, there is a risk that our products may be pushed to the back of the shelf, misplaced, or even out of stock, negatively impacting our brand visibility and potential sales.

                </p>
                <p><b>Leveraging Mystery Shopping and Auditing Companies as a Solution:</b></p>
                <p>Mystery shopping and auditing companies offer a strategic solution to monitor and maintain on-shelf availability. By utilizing their services, we can achieve:
                </p>
                <p><b>Regular Product Checks:</b> These companies deploy field agents who visit our partner retailers and conduct regular checks of our product placement and availability. These field agents are equipped with a checklist that includes key elements of our product stocking and placement agreements.


                </p>
                <p><b>Adherence Reporting:</b> Field agents provide detailed reports, often including photographs, to document their findings during their store visits. This information is invaluable as it allows us to assess whether retailers are adhering to the agreed-upon stocking and placement guidelines.


                </p>
                <p><b>Actionable Feedback:</b> The feedback and reports from the field agents are instrumental in identifying areas where improvements are needed. When discrepancies are identified with your internal stock management software data, we can take corrective actions promptly. This may involve communication with retailers to reinforce our agreements or provide additional guidelines.


                </p>
                <p><b>Brands that have leveraged audit programs to close the market gap and reach underserved customers:
                </b></p>
                <ul>
                    <Link to="/casestudies/garmin" style={{ textUnderlineOffset: "8px", color: "#007DC1" }}> <li style={{ listStyle: "inside", marginBottom: ".5rem" }}> <b> Garmin :</b> <span>"Garmin wanted to check the stock availabiltiy and the product display at the store to understand the reason behind sales count and whether the staff have the knowleadge of the product to communicate to the customer. Also to check whether the sales persons are in adherence to the Net retail price policy of the company.
"</span></li></Link>
                    <Link to="/casestudies/vajor" style={{ textUnderlineOffset: "8px", color: "#007DC1" }}> <li style={{ listStyle: "inside" }}> <b> Vajor :</b> <span>The challenge at hand was to ensure that the product presentation within the company's retail outlets aligns with established standards. This necessitated a comprehensive evaluation of the product display, alongside an assessment of staff knowledge, grooming, store ambience, and marketing elements</span></li></Link>
                </ul>
                <p><b>Conclusion: </b> As a retail/sales manager, maintaining on-shelf availability is a vital part of our brand's success and customer satisfaction. Mystery shopping and auditing companies provide a proactive solution to monitor the adherence of partner retailers to product stocking and placement agreements. In today's competitive retail environment, this level of monitoring and compliance is essential for maintaining brand visibility and meeting customer demand.

                </p>
            </div>

            <div className='blog_button_container'>
                <button
                    className="button mb-5 mt-3 btn-md text-center btn-size create_ac_btn"
                    onClick={() =>
                        setModalShow(
                            true,
                        )
                    }
                >
                    Talk to a consultant
                </button>
                <Link to="/exploremarketplace">
                    <button
                        className="button mb-5 mt-3 btn-md text-center btn-size create_ac_btn"
                    >
                        Explore Marketplace
                    </button>
                </Link>
            </div>
            <Modal
                show={modalShow}
                centered
                size='lg'
                style={{ marginTop: '40px' }}
                scrollable={true}
                className='modal-dialog-scrollable p-6 modal_mobile'
                onHide={() => setModalShow(false)}>
                <div style={{ position: "absolute", right: "2rem", top: ".8rem", cursor: "pointer", }}
                    onClick={() => setModalShow(false)}
                ><i className="fa-solid fa-x"></i></div>
                <iframe
                    frameBorder='0'
                    style={{
                        height: '500px',
                        width: '99%',
                        border: 'none',
                    }}
                    src='https://forms.zohopublic.com/floorwalk/form/MysteryShoppingServices/formperma/xW3CS_zHKW2DcCLHVZUplXsC1wLR_fITFv3kCFrBY9U?zf_rszfm=1'></iframe>
            </Modal>
        </>
    )
}
