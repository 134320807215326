import { Container, Stack, Row, Col } from "react-bootstrap";
import { IoArrowForwardOutline } from "react-icons/io5";
import "../../styles/homeproducts.css";
import { useFetch } from '../../utils/hooks/useFetch';
import base_url from "../../utils/config";
import { Jodit } from 'jodit-react';
import { Link } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../../App";


const HomeProducts = () => {
    const categoryId = localStorage.getItem("selectedCategoryId");
    // const categoryId = 19; // for live
    // const {data,setData, loading} = useFetch(base_url + "manager/public_category/" + categoryId + "/solution_detail")
    const {productData:data} = useContext(AppContext);
  return (
    <div className="FloorWalkMarketplace_wrapper">
        
      <Container>
        <Stack className="flex-column ">
          <h2 className="text-center text-white section-title">
            FloorWalk Marketplace
          </h2>
          <p className="text-center text-white">
            Turn your research ideas into reality—select a solution, set up your project, and get insights in no time.
          </p>
        </Stack>
        <div className="g-4 marketplace_item_container">
          {data[0]?.solutions.map((item) => (
            <div className="marketplace_item">
              <Row>
              <Col lg={4}>
                <img src={item?.attachments[0]?.preview_url} alt={item?.proof_type} className="marletplace_item_img"/>
              </Col>
              <Col lg={8}>
                <h4 style={{ color: "#003C5D" }} className="heading-4">
                  {item?.name}
                </h4>
                <p className="text-lg">{Jodit.modules.Helpers.stripTags(item?.short_description)}</p>
              </Col>
              </Row>
              <div className="d-flex justify-content-end">
                 <Link to={item?.url_structure} state={{ id: item?.id }} style={{ textDecoration: "none", color: "black" }}>
                <button className="learn_more_btn">
                  Learn More <IoArrowForwardOutline />
                </button>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default HomeProducts;
