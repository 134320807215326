
import { Card } from "react-bootstrap";
import { IoArrowForwardOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

const ReadingCard = ({
  image,
  logo,
  title,
  description,
  buttonText = "Learn More",
  onButtonClick,
  linkto
}) => {
  return (
    <Link to={linkto} style={{textDecoration:"none"}}>
    <Card className="reading_card">
      <Card.Img className="reading_card_img" variant="top" src={image} />
      <Card.Body className="w-full">
        {/* <img src={logo} alt="brand logo" className="reading_card_brand_logo" /> */}
        <Card.Title className="reading_card_title">{title}</Card.Title>
        <Card.Text className="reading_card_description">
          {description}
        </Card.Text>
      </Card.Body>
      <div>
        <button className="learn_more_btn" onClick={onButtonClick}>
          {buttonText} <IoArrowForwardOutline />
        </button>
      </div>
    </Card>
    </Link>
  );
};
export default ReadingCard;
