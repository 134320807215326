// import { GoArrowUpRight } from "react-icons/go";
// import { HiMiniCheckBadge } from "react-icons/hi2";
// import { Jodit } from 'jodit-react';

// const PricingCard = (
// //   {
// //   title,
// //   subTitle,
// //   price,
// //   gst,
// //   totalCity,
// //   slug,
// //   inclusions,
// // }
// feature
// ) => {
//   return (
//     <div className="pricing_detail_card">
//       <div className="pricing_detail_card_left">
//       {/* <h3 className="pricing_detail_card_left_title">{title}</h3> */}
//       <h3 className="pricing_detail_card_left_title">{feature?.name}</h3>
//       {/* <p className="pricing_detail_card_left_des">{subTitle}</p> */}
//       <p className="pricing_detail_card_left_des">{Jodit.modules.Helpers.stripTags(feature?.short_description)}</p>
//         <h4 className="pricing_detail_card_left_price">
//         {/* {price} <span>/month</span> */}
//         {feature?.price_INR} <span>/month</span>
//         </h4>
//         {/* <h6 className="pricing_detail_card_left_gst">{gst}</h6> */}
//         <h6 className="pricing_detail_card_left_gst">{feature?.tax?.rate}</h6>
//         {/* <h5 className="pricing_detail_card_left_city_count">
//           {totalCity} <span>City</span>
//         </h5> */}
//         <button className="button button-primary w-full">Get Started</button>
//       </div>
//       {/* <FeatureList inclusions={inclusions} /> */}
//     </div>
//   );
// };

// // const FeatureList = ({ inclusions }) => (
// //   <div className="pricing_detail_card_right">
// //     <h4 className="pricing_detail_card_right_title">Inclusions</h4>
// //     <ul className="d-flex flex-column gap-2 p-0 m-0">
// //       {inclusions.map((inclusion, i) => (
// //         <li
// //           key={i}
// //           style={{ color: i === 0 ? "#007DC1" : "#4B5563" }}
// //           className="d-flex justify-content-between align-items-center gap-2"
// //         >
// //           <span className="d-flex align-items-center gap-2">
// //             <HiMiniCheckBadge size={27} />
// //             <span
// //               style={{
// //                 flex: "1",
// //                 fontSize: "16px",
// //                 lineHeight: "115%",
// //               }}
// //             >
// //               {inclusion}
// //             </span>
// //           </span>
// //           {i === 0 && <GoArrowUpRight size={28} />}
// //         </li>
// //       ))}
// //     </ul>
// //   </div>
// // );

// const PricingSection = ({ data }) => {
//   console.log("6999",data)
//   return (
//     <div className="d-flex flex-column gap-4 pricing-content-container" >
//       {data?.map((feature) => (
//         <PricingCard key={feature.id} {...feature} />
//       ))}
//     </div>
//   );
// };

// export default PricingSection;

import { GoArrowUpRight } from "react-icons/go";
import { HiMiniCheckBadge } from "react-icons/hi2";
import { Jodit } from 'jodit-react';
import { useNavigate } from "react-router-dom";

const PricingCard = ({ feature }) => {
  const navigate = useNavigate();

  const handleNavigate = (feature) => {
    navigate(`/${feature?.url_structure}`, {state:{id:feature?.id}})
  }
  return (
    <div className="pricing_detail_card">
      <div className="pricing_detail_card_left">
        <h3 className="pricing_detail_card_left_title">{feature?.name}</h3>
        <p className="pricing_detail_card_left_des">
          {Jodit.modules.Helpers.stripTags(feature?.short_description)}
        </p>
        <h4 className="pricing_detail_card_left_price">
          {localStorage.getItem("selectedCountry")?
                                            localStorage.getItem("selectedCountry") === "IN" ?
                                                <>INR. {feature?.price_INR}<span></span></>
                                                :
                                                <>USD. {feature?.price_USD}<span></span></>
                                                :
                                                <>INR. {feature?.price_INR}<span></span></>
                                            }
        </h4>
        <h6 className="pricing_detail_card_left_gst">+ {feature?.tax?.rate}% GST</h6>
        <button className="button button-primary w-full" onClick={()=>handleNavigate(feature)}>Get Started</button>
      </div>
      <FeatureList overview={feature?.overview} />
    </div>
  );
};

const FeatureList = ({ overview }) => {
  // Extract text from <li> elements instead of raw HTML
  const parseOverview = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    return Array.from(doc.querySelectorAll('li')).map(li => li.textContent.trim());
  };

  const inclusions = overview ? parseOverview(overview) : [];

  return (
    <div className="pricing_detail_card_right">
      <h4 className="pricing_detail_card_right_title">Inclusions</h4>
      <ul className="d-flex flex-column gap-2 p-0 m-0">
        {inclusions.map((content, i) => (
          <li
            key={i}
            style={{ color: i === 0 ? "#007DC1" : "#4B5563" }}
            className="d-flex justify-content-between align-items-center gap-2"
          >
            <span className="d-flex align-items-center gap-2">
              <HiMiniCheckBadge size={27} />
              <span style={{ flex: "1", fontSize: "16px", lineHeight: "115%" }}>
                {content}
              </span>
            </span>
            {i === 0 && <GoArrowUpRight size={28} />}
          </li>
        ))}
      </ul>
    </div>
  );
};

const PricingSection = ({ data }) => {
  return (
    <div className="d-flex flex-column gap-4 pricing-content-container">
      {data?.map((feature) => (
        <PricingCard key={feature.id} feature={feature} />
      ))}
    </div>
  );
};

export default PricingSection;
