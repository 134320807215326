import React, { useState,useEffect } from 'react';
import { Container, Row, Col, Alert, Modal } from 'react-bootstrap';
import "../../styles/registration.css";
import base_url from '../../utils/config';
import { Link } from 'react-router-dom';
import { EmailVerification } from './Login';
import accountpic from "../../images/account_pic.png";

export default function Registration() {
    const [modalShow, setModalShow] = useState(false);
    const [userId, SetUserId] = useState(0);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        username: '',
        phone: '',
        password: '',
        brand: '',
        first_name: '',
        last_name: '',
    });
    const [errorMessages, setErrorMessages] = useState({
        usernameError: '',
        phoneError: '',
        passwordError: '',
        brandError: '',
        first_nameError: '',
        last_nameError: '',
    });
    const [errorMessage, setErrorMessage] = useState("");
    const [hasError, setHasError] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [reponseMail, setResponseMail] = useState("");

    useEffect(()=>{
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
      },[]) 

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        setErrorMessages(prevErrors => ({
            ...prevErrors,
            [`${name}Error`]: ''
        }));
    };

    
	const handleTry =()=>{
		console.log("hello")
		const requestData = {
			username: "bahni@gmail.com",
			phone: "7999369263",
			password: "12345678",
		};
			fetch(base_url + "auditor/app/signup_api_app", {
			  method: 'POST',
			  headers: {
				'Content-Type': 'application/json'
			  },
			  body: JSON.stringify(requestData)
			})
			.then(response => response.json())
			
			.then(data => {
			  console.log("registered",data)
			//   setErrorMessage(data.detail)
			//   setForm({ 
			// 	userName: "", 
			// 	password: "",
			// 	phone : "",
			//    });
			})
			.catch(error => {
			  console.error("API Request Error:", error);
			})
	}


    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(false);
        if (!formData.username) {
            setErrorMessages(prevErrors => ({
                ...prevErrors,
                usernameError: "email can't be empty"
            }));
            setHasError(true)
            setTimeout(() => {
                setErrorMessages(prevErrors => ({
                    ...prevErrors,
                    usernameError: ""
                }));
                setHasError(false);
            }, 1200)
        }

        if (!formData.password) {
            setErrorMessages(prevErrors => ({
                ...prevErrors,
                passwordError: "Password can't be empty"
            }));
            setHasError(true)
            setTimeout(() => {
                setErrorMessages(prevErrors => ({
                    ...prevErrors,
                    passwordError: ""
                }));
                setHasError(false);
            }, 1200)
        }
        if (!formData.brand) {
            setErrorMessages(prevErrors => ({
                ...prevErrors,
                brandError: "Brand can't be empty"
            }));
            setHasError(true)
            setTimeout(() => {
                setErrorMessages(prevErrors => ({
                    ...prevErrors,
                    brandError: ""
                }));
                setHasError(false);
            }, 1200)
        }
        if (!formData.first_name) {
            setErrorMessages(prevErrors => ({
                ...prevErrors,
                first_nameError: "First name can't be empty"
            }));
            setHasError(true)
            setTimeout(() => {
                setErrorMessages(prevErrors => ({
                    ...prevErrors,
                    first_nameError: ""
                }));
                setHasError(false);
            }, 1200)
        }

        if (!formData.phone) {
            setErrorMessages(prevErrors => ({
                ...prevErrors,
                phoneError: "Mobile number can't be empty"
            }));
            setHasError(true)
            setTimeout(() => {
                setErrorMessages(prevErrors => ({
                    ...prevErrors,
                    phoneError: ""
                }));
                setHasError(false);
            }, 1200)
        }

        const isValidMobileNumber = /^[0-9]{10}$/.test(formData.phone.trim());

        if (formData.phone && !isValidMobileNumber) {
            setErrorMessages(prevErrors => ({
                ...prevErrors,
                phoneError: "Mobile number should be a 10-digit number"
            }));
            setHasError(true);
            setTimeout(() => {
                setErrorMessages(prevErrors => ({
                    ...prevErrors,
                    phoneError: ""
                }));
                setHasError(false);
            }, 1200)
            
        }

        if (formData.username && formData.password && formData.brand && formData.first_name && formData.phone && isValidMobileNumber) {
            const requestData = {
                username: formData.username,
                phone: formData.phone,
                password: formData.password,
                brand: formData.brand,
                first_name: formData.first_name,
                last_name: formData.last_name,
            };
            
            // if ((formData.phone && isValidMobileNumber)) {
                setLoading(true);
                // fetch(base_url + "client/market_place/signup_api", {
                fetch(base_url + "auditor/app/signup_api_app", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(requestData)
                })
                    .then(response => response.json())
                    .then(data => {
                        setResponseMail(data?.email);
                        setHasError(false);
                        setShowAlert(true);
                        setTimeout(() => {
                            setShowAlert(false);
                            setLoading(false);
                        }, 1700)
                        setErrorMessage(data);
                        SetUserId(data.user);
                        if (data.detail === "Client Registered Successfully. Please Check Email for OTP Verification...") {
                            setModalShow(true);
                        }
                        if (data && data.client_dashboard_data.client_profile_info.user_id) {
                            localStorage.setItem('user_id', data.client_dashboard_data.client_profile_info.user_id);
                            localStorage.setItem('token', data.token);
                            localStorage.setItem('client_id', data.client_dashboard_data.client_profile_info.client_id);
                            setModalShow(false)
                        }
                        setFormData({
                            username: '',
                            phone: '',
                            password: '',
                            brand: '',
                            first_name: '',
                            last_name: '',
                        });
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    }).finally(() => {
                        setLoading(false);
                    });
            // }

        }
    };
    return (
        <>
            <Container fluid className='registration_container'>
                <Row>
                    <Col md="6" className='registration_left_part'>
                        <Row className='registration_left_part_upper'>
                            <h5>HOW IT WORKS</h5>
                            <div className='iframe_container'>
                                <iframe src="https://www.youtube.com/embed/yW7X1FdkS4A" title='floorwalk_registration'></iframe>
                            </div>
                        </Row>
                        <div className='registration_logo_box_container'>
                            <Col className='registration_logo_box'>
                                <img src={accountpic} alt="ac-logo" />
                                <p>Create Your Account</p>
                            </Col>
                            <Col className='registration_logo_box'>
                                <img src={accountpic} alt="ac-logo" />
                                <p>Select Your Solution</p>
                            </Col>
                            <Col className='registration_logo_box'>
                                <img src={accountpic} alt="ac-logo" />
                                <p>Build Your <br /> Live Project</p>
                            </Col>
                            <Col className='registration_logo_box'>
                                <img src={accountpic} alt="ac-logo" />
                                <p>Get Insights <br /> within Days</p>
                            </Col>
                        </div>
                    </Col>
                    <Col md="6" className='registration_right_part'>
                        {showAlert && (<Alert variant={errorMessage.detail === "Client Registered Successfully. Please Check Email for verification" ? "success" : "danger"} onClose={() => setShowAlert(false)} dismissible>
                            {errorMessage.details}
                        </Alert>
                        )}
                        <Row>
                            <h5>CREATE YOUR ACCOUNT</h5>
                        </Row>
                        <Row>
                            <p>Floorwalk Marketplace is B2B retail audit and research solution designed to help you get your insights quicker and easier. Select your soluton, build your project, purchase on the go and get started without any delays.</p>
                        </Row>

                        <button
                                onClick={handleTry}
                                    className="btn mb-5 mt-3 btn-md text-center btn-size create_ac_btn"
                                >
                                    Create
                            </button>

                        <form onSubmit={handleSubmit}>
                            <Row>
                                <Col md="6">
                                    <p className="registration_form_content">
                                        <label>First Name<span>*</span></label>
                                        <input
                                            type="text"
                                            name='first_name'
                                            value={formData.first_name}
                                            onChange={handleChange}
                                            className={hasError ? "error_border" : "success_border"}
                                        />
                                        {errorMessages.first_nameError && (
                                            <span className="error_message">{errorMessages.first_nameError}</span>
                                        )}
                                    </p>
                                </Col>
                                <Col md="6">
                                    <p className="registration_form_content">
                                        <label>Last Name</label>
                                        <input
                                            type="text"
                                            name='last_name'
                                            value={formData.last_name}
                                            onChange={handleChange}
                                            className={hasError ? "error_border" : "success_border"}
                                        />
                                        {errorMessages.last_nameError && (
                                            <span className="error_message">{errorMessages.last_nameError}</span>
                                        )}
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6">
                                    <p className="registration_form_content">
                                        <label>Email<span>*</span></label>
                                        <input
                                            type="email"
                                            name='username'
                                            value={formData.username}
                                            onChange={handleChange}
                                            className={hasError ? "error_border" : "success_border"}
                                        />
                                        {errorMessages.usernameError && (
                                            <span className="error_message">{errorMessages.usernameError}</span>
                                        )}
                                    </p>
                                </Col>
                                <Col md="6">
                                    <p className="registration_form_content">
                                        <label>Brand Name<span>*</span></label>
                                        <input
                                            type="text"
                                            name='brand'
                                            value={formData.brand}
                                            onChange={handleChange}
                                            className={hasError ? "error_border" : "success_border"}
                                        />
                                        {errorMessages.brandError && (
                                            <span className="error_message">{errorMessages.brandError}</span>
                                        )}
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="2" className='bigscrn_mobilenumber'>
                                    <p className="registration_form_content">
                                        <label htmlFor="registration_phone">Mobile<span>*</span></label>
                                        <select name="country_codes" id="country_codes">
                                            <option value="+91">+91</option>
                                        </select>
                                    </p>
                                </Col>
                                <Col md="4" className='bigscrn_mobilenumber'>
                                    <p className="registration_form_content">
                                        <label style={{ visibility: "hidden" }}>Mobile</label>
                                        <input
                                            type="tel"
                                            value={formData.phone}
                                            name='phone'
                                            onChange={handleChange}
                                            className={hasError ? "error_border" : "success_border"}
                                        />
                                        {errorMessages.phoneError && (
                                            <span className="error_message">{errorMessages.phoneError}</span>
                                        )}
                                    </p>

                                </Col>
                                {/* ------------------------------------------MOBILE VIEW------------------------------------------- */}
                                <Col md="6" className='smallscrn_mobilenumber'>
                                     <label>Mobile <span style={{color:"red"}}>*</span></label>
                                        <p className="registration_form_content" style={{flexDirection:"row"}}>
                                            <select name="country_codes" id="country_codes" style={{width:"20%",padding:"0px",paddingLeft:"5px"}}>
                                                <option value="+91">+91</option>
                                            </select>
                                            <input
                                                type="tel"
                                                value={formData.phone}
                                                style={{width:"80%"}}
                                                name='phone'
                                                onChange={handleChange}
                                                className={hasError ? "error_border" : "success_border"}
                                            />
                                            {errorMessages.phoneError && (
                                                <span className="error_message">{errorMessages.phoneError}</span>
                                            )}
                                        </p>
                                    </Col>
{/* ------------------------------------------------------------------------------------------------------------ */}
                                <Col md="6">
                                    <p className="registration_form_content">
                                        <label>Password<span>*</span></label>
                                        <input
                                            type="password"
                                            name='password'
                                            value={formData.password}
                                            onChange={handleChange}
                                            className={hasError ? "error_border" : "success_border"}
                                        />
                                        {errorMessages.passwordError && (
                                            <span className="error_message">{errorMessages.passwordError}</span>
                                        )}
                                    </p>
                                </Col>
                            </Row>
                            <Row className='registration_form_terms'>
                                <h5>By clicking create account you agree to the terms of use and privacy policy</h5>
                            </Row>
                            {loading ?

                                <button className="button mb-5 btn-md text-center btn-size create_ac_btn" type="button" disabled style={{ fontSize: "1rem", background: "#007DC1", color: "white", }}>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: ".5rem" }}></span>
                                    Loading...
                                </button>

                                :
                                <button
                                    className="button mb-5 mt-3 btn-md text-center btn-size create_ac_btn"
                                >
                                    Create Account
                                </button>}
                                
                            <Row>
                                <h5 style={{ fontSize: ".9rem" }}>Already have an account? <Link to="/accountinfo/login" style={{ textUnderlineOffset: "5px" }}>Login</Link></h5>
                            </Row>
                        </form>
                    </Col>
                </Row>
                <Modal
                    show={modalShow}
                    centered
                    size='lg'
                    style={{ marginTop: '0px', background: "rgba(0,0,0,.7)", height: "100vh" }}
                    // scrollable={true}
                    className='modal-dialog-scrollable p-6 modal_mobile'
                    onHide={() => setModalShow(false)}
                >
                    <div
                        style={{
                            width: '100%',
                            height: "50vh",
                            padding: "2rem 3rem",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            border: 'none',
                        }}
                    >
                        <div style={{ position: "absolute", right: "2rem", top: "2rem", cursor: "pointer" }} onClick={() => setModalShow(false)}><i className="fa-solid fa-x"></i></div>
                        <EmailVerification userId={userId}  responseMail={reponseMail}/>
                    </div>
                </Modal>
            </Container>
        </>
    )
}
