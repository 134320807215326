import React from 'react';
import { Col,Row,Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import cardimg from "../../../images/card.png";
import indicard from "../../../images/indicard.jpg";
import shotcard from "../../../images/shotcard.jpg";

export default function CommonCards() {
  return (
    <Container fluid className='homepage_brand_container' style={{ marginTop: "2rem" }}>
    <Row className='homepage_brand casestudy_box_container' style={{paddingTop:"1rem"}}>
    <Col className='homepage_brand_box casestudy_box' style={{ borderRadius: "7px" }}>
                    <Link to="/casestudies/amazon">
                        <div className='homepage_brand_img'>
                            <img src={cardimg} alt="manyvarimg" style={{ borderTopLeftRadius: "7px", borderTopRightRadius: "7px",height:"auto" }} />
                        </div>
                        <div className='homepage_brand_text casestudy_brand_text'>
                            <div>
                                <h5 style={{ marginLeft: "1rem",fontWeight:"bold" }}>Amazon</h5>
                                <p style={{ margin: "0rem 1rem" }}>The client wanted to study the Market price of the various television models. This was mainly to understand price gaps in the online and offline market.</p>
                                <div style={{ marginTop: "1rem", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0rem 1rem" }}>
                                    <button className="button btn-md text-center btn-size create_ac_btn">Online Service</button>
                                    <div style={{ border: "2px solid black", padding: "3px 7px", borderRadius: "50%" }}>
                                        <i className="fa-solid fa-arrow-right" style={{ marginTop: "3px", fontSize: "1.2rem" }}></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </Link>
                    </Col>
    <Col className='homepage_brand_box casestudy_box' style={{ borderRadius: "7px" }}>
        <Link to="/casestudies/indyverse">
        <div className='homepage_brand_img'>
            <img src={indicard} alt="manyvarimg" style={{ borderTopLeftRadius: "7px", borderTopRightRadius: "7px",height:"auto" }} />
        </div>
        <div className='homepage_brand_text casestudy_brand_text'>
            <div>
                 <h5 style={{ marginLeft: "1rem",fontWeight:"bold" }}>Indyverse</h5>
                <p style={{ margin: "0rem 1rem" }}>The challenge at hand was to bolster sales by leveraging customer reviews and ratings as a means to enhance brand visibility in the target market. </p>
                 <div style={{ marginTop: "1rem", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0rem 1rem" }}>
                    <button className="button btn-md text-center btn-size create_ac_btn">Online Service</button>
                    <div style={{ border: "2px solid black", padding: "3px 7px", borderRadius: "50%" }}>
                        <i className="fa-solid fa-arrow-right" style={{ marginTop: "3px", fontSize: "1.2rem" }}></i>
                    </div>
                </div>
            </div>
        </div>
        </Link>
    </Col>
    <Col className='homepage_brand_box casestudy_box' style={{ borderRadius: "7px" }}>
        <Link to="/casestudies/shott">
        <div className='homepage_brand_img'>
            <img src={shotcard} alt="manyvarimg" style={{ borderTopLeftRadius: "7px", borderTopRightRadius: "7px",height:"auto" }} />
        </div>
        <div className='homepage_brand_text casestudy_brand_text'>
            <div>
                <h5 style={{ marginLeft: "1rem",fontWeight:"bold" }}>Shott</h5>
                <p style={{ margin: "0rem 1rem" }}>The challenge at hand was to ensure that the product presentation within the company's retail outlets aligns with established standards... </p>
                <div style={{ marginTop: "1rem", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0rem 1rem" }}>
                    <button className="button btn-md text-center btn-size create_ac_btn">Entertainment</button>
                    <div style={{ border: "2px solid black", padding: "3px 7px", borderRadius: "50%" }}>
                        <i className="fa-solid fa-arrow-right" style={{ marginTop: "3px", fontSize: "1.2rem" }}></i>
                    </div>
                </div>
            </div>
        </div>
        </Link>
    </Col>

</Row>
</Container>
  )
}
