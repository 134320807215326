import React, { useEffect, useState } from "react";
import { Row, Col, Table, Modal, Button } from "react-bootstrap";
import Select from "react-select";
import base_url from "../utils/config";
import {
  deleteLocationData,
  postLocationData,
  updateLocationData,
} from "../utils/services/PostdataHandle";
import { useDispatch, useSelector } from "react-redux";
import {
  addLocation,
  deleteLocation,
  updateLocation,
} from "../utils/locationSlice";

export default function AuditSetup1LocationSelector({ onCitySelectionChange }) {
  const clientId = localStorage.getItem("client_id");
  const dispatch = useDispatch();
  const addedLocationsRedux = useSelector((state) => state.locations.locations);
  const [locationData, setLocationData] = useState({
    state: "",
    cities: [],
  });
  const [errorMessages, setErrorMessages] = useState({});
  const [loading, setLoading] = useState(false);
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [addedLocations, setAddedLocations] = useState([]);
  const [editId, setEditId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    getApiData();
    if (addedLocationsRedux) {
      setAddedLocations(addedLocationsRedux);
      updateTotalSelectedCities(addedLocationsRedux);
    }
  }, [addedLocationsRedux]);

  const updateTotalSelectedCities = (locations) => {
    const total = locations.reduce((acc, loc) => acc + loc.cities.length, 0);
    onCitySelectionChange(total); 
  };

  const handleLocationChange = (e) => {
    const { name, value } = e.target;
    setLocationData({ state: value, cities: [] }); // Reset cities on state change
    setErrorMessages((prevErrors) => ({
      ...prevErrors,
      stateError: name === "state" && value ? "" : prevErrors.stateError,
    }));
    if (name === "state") fetchCityData(value);
  };

  const fetchCityData = async (stateCode) => {
    try {
      const response = await fetch(`${base_url}manager/city/${stateCode}`);
      if (response.ok) {
        setCityData(await response.json());
      } else setCityData([]);
    } catch (error) {
      console.error("City fetch error:", error);
      setCityData([]);
    }
  };

  const getApiData = async () => {
    try {
      const response = await fetch(base_url + "manager/state");
      if (response.ok) {
        const result = await response.json();
        setStateData(
          Object.entries(result).map(([stateCode, stateName]) => ({
            stateCode,
            stateName,
          }))
        );
      }
    } catch (error) {
      console.error("State fetch error:", error);
    }
  };

  const handleCityChange = (selectedOptions) => {
    let existingCitiesCount = 0;

    if (editId) {
      const editingLocation = addedLocations.find((loc) => loc.id === editId);
      existingCitiesCount = editingLocation
        ? editingLocation.cities_data[0].city_data.length
        : 0;
    }

    // Calculate the total count considering the edited location separately
    const totalSelectedCities =
      addedLocations.reduce((acc, loc) => acc + loc.cities.length, 0) -
      existingCitiesCount + // Remove existing count from total
      selectedOptions.length; // Add the new selection

    if (totalSelectedCities > 10) {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        citiesError: "You can select a maximum of 10 cities across all states.",
      }));
      return;
    }

    setLocationData((prev) => ({ ...prev, cities: selectedOptions }));
    updateTotalSelectedCities([...addedLocations, { cities: selectedOptions }]);
    setErrorMessages((prevErrors) => ({
      ...prevErrors,
      citiesError: selectedOptions.length ? "" : prevErrors.citiesError,
    }));
  };

  const handleAddOrUpdate = () => {
    if (!locationData.state || !locationData.cities.length) {
      setErrorMessages({
        stateError: locationData.state ? "" : "State is required",
        citiesError: locationData.cities.length
          ? ""
          : "Select at least one city",
      });
      return;
    }

    setLoading(true);
    const requestData = {
      cities: locationData.cities.map((c) => c.value),
      client: +clientId,
    };

    if (editId) {
      // Update existing entry
      updateLocationData(requestData, `client/mp/state_city_mapping/${editId}`)
        .then((response) => response.json())
        .then((data) => {
          dispatch(updateLocation(data));
          setAddedLocations((prev) =>
            prev.map((item) => (item.id === editId ? data : item))
          );
          resetForm();
        })
        .catch((error) => console.error("Update error:", error))
        .finally(() => setLoading(false));
    } else {
      // Add new entry
      postLocationData(requestData, "client/mp/state_city_mapping")
        .then((response) => response.json())
        .then((data) => {
          dispatch(addLocation(data));
          setAddedLocations([...addedLocations, data]);
          resetForm();
        })
        .catch((error) => console.error("Add error:", error))
        .finally(() => setLoading(false));
    }
  };

  const handleDeleteClick = (id) => {
    setDeleteId(id);
    setShowDeleteModal(true);
  };
  const handleDeleteConfirm = () => {
    deleteLocationData(`client/mp/state_city_mapping/${deleteId}`)
      .then(() => {
        dispatch(deleteLocation(deleteId));
        setAddedLocations(
          addedLocations.filter((item) => item.id !== deleteId)
        );
        setShowDeleteModal(false);
        setDeleteId(null);
      })
      .catch((error) => console.error("Delete error:", error));
  };
  const resetForm = () => {
    setLocationData({ state: "", cities: [] });
    setEditId(null);
    setErrorMessages({});
  };

  const handleEdit = (location) => {
    setLocationData({
      state: location.cities_data[0].state,
      cities: location.cities_data[0].city_data.map((city) => ({
        value: city.id,
        label: city.name,
      })),
    });
    setEditId(location.id);
    fetchCityData(location.cities_data[0].state);
  };

  const totalCitiesCount = addedLocations.reduce(
    (acc, loc) => acc + loc.cities.length,
    0
  );
  const isDisabled = !editId && totalCitiesCount >= 10;

  return (
    <>
      <Row>
        <Col md="4">
          <p className="registration_form_content">
            <label className="form-label fw-bold fs-6 mb-3">
              State<span>*</span>
            </label>
            <select
              name="state"
              onChange={handleLocationChange}
              value={locationData.state}
              disabled={isDisabled}
            >
              <option value="">Select</option>
              {stateData.map((item) => (
                <option key={item.stateCode} value={item.stateCode}>
                  {item.stateName}
                </option>
              ))}
            </select>
            {errorMessages.stateError && (
              <span className="error_message">{errorMessages.stateError}</span>
            )}
          </p>
        </Col>

        <Col md="4">
          <label className="form-label fw-bold fs-6 mb-4">
            City<span style={{ color: "red", marginLeft: ".3rem" }}>*</span>
          </label>
          <Select
            name="cities"
            value={locationData.cities}
            onChange={handleCityChange}
            options={cityData.map((city) => ({
              value: city.id,
              label: city.name,
            }))}
            isMulti
            isDisabled={isDisabled}
            // isOptionDisabled={(option) =>
            //   totalCitiesCount >= 10 && !locationData.cities.some(city => city.value === option.value)
            // }
          />
          {errorMessages.citiesError && (
            <span
              className="error_message"
              style={{ color: "#ff6f6f", fontSize: ".9rem" }}
            >
              {errorMessages.citiesError}
            </span>
          )}
        </Col>

        <Col md="4" style={{ marginTop: "2.5rem" }}>
          <button
            className="button btn-md text-center btn-size create_ac_btn"
            onClick={handleAddOrUpdate}
            disabled={isDisabled || loading} 
          >
            {loading
              ? editId
                ? "Updating..."
                : "Adding..."
              : editId
              ? "Update"
              : "Add"}
          </button>
        </Col>
      </Row>

      {/* Display Added Locations */}
      {addedLocations.length > 0 ? (
        <Table striped bordered hover className="mt-4">
          <thead>
            <tr>
              <th>#</th>
              <th>State</th>
              <th>Cities</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {addedLocations.map((location, index) => (
              <tr key={location.id}>
                <td>{index + 1}</td>
                <td>{location.cities_data[0].state_name}</td>
                <td>
                  {location.cities_data[0].city_data
                    .map((city) => city.name)
                    .join(", ")}
                </td>
                <td>
                  <button
                    className="btn btn-sm"
                    onClick={() => handleEdit(location)}
                  >
                    <i
                      className="fa-solid fa-pen-to-square"
                      style={{ color: "green" }}
                    ></i>{" "}
                  </button>
                  <button
                    className="btn btn-sm ms-2"
                    onClick={() => handleDeleteClick(location.id)}
                  >
                    <i
                      className="fa-solid fa-trash"
                      style={{ color: "#ff6f6f" }}
                    ></i>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : null}
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this location?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteConfirm}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
