import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Col, Alert } from 'react-bootstrap';
import "../../../styles/dashboardbody.css";
import { useDispatch } from 'react-redux';
import { clearCart } from '../../../utils/cartSlice';
import { clearStoreData } from '../../../utils/orderdataslice';
import { HeaderMenu } from '../../Header';
import { removeFile } from '../../../utils/orderdataslice';
import floorwalklogo from '../../../images/floorwalk_logo.png';
import floorwalklogosmall from "../../../images/Logo_copy.png";
import base_url from '../../../utils/config';
import { resetLocations } from '../../../utils/locationSlice';
import { removeAuthData } from '../../../utils/authSlice';

export default function DashboardHeader() {
  const navigate = useNavigate();
  const token = (localStorage.getItem('token'));
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const dispatch = useDispatch();
  const [profileData, setProfileData] = useState([]);

  useEffect(() => {
    getProfileData("client/client_profile");
  }, [isOpenSidebar])

  const getProfileData = async (url) => {
    try {
      const headers = {
        'Authorization': `Token ${token}`,
        'Content-Type': 'application/json'
      };

      const options = {
        method: 'GET',
        headers: headers
      };

      const response = await fetch(base_url + url, options);

      if (response.ok) {
        const result = await response.json();
        setProfileData(result);
      } else {
        console.error('API request failed:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('An error occurred while fetching the API:', error);
    }
  };

  function handleClickSidebar() {
    setIsOpenSidebar(!isOpenSidebar);
  }
  function handleLogo() {
    dispatch(clearCart());
    dispatch(clearStoreData());
    dispatch(removeFile());
    dispatch(resetLocations())
    localStorage.removeItem('orderId');
    window.location.reload();
  }
  function handleLogout() {
    dispatch(clearCart());
    dispatch(clearStoreData());
    dispatch(removeFile());
    dispatch(resetLocations());
    dispatch(removeAuthData());
    localStorage.removeItem('orderId');
    localStorage.removeItem('user_id');
    localStorage.removeItem('token');
    localStorage.removeItem('client_id');
    navigate("/");
}
  return (
    <section className="nav_bar">
      {/* <Container fluid> */}
        <div className="nav_bar_main">
          <Col md="1" style={{paddingLeft:"1rem"}}>
          <i className="fa-solid fa-bars" onClick={handleClickSidebar}></i>
          </Col>
        </div>
      {/* </Container> */}
      <div className="logosmall" onClick={handleLogo}>
          <Link to="/">
            <img
              src={floorwalklogosmall}
              alt="floorwalk_logo"
              width="120px"
            />
          </Link>
        </div>

      {/**************************************SIDEBAR*************************************************/}

      {/* <div className={isOpenSidebar ? 'sidebar_nav_menu_container active' : 'sidebar_nav_menu_container'}> */}
      <div className={isOpenSidebar ? 'sidebar_nav_menu_container active' : 'sidebar_nav_menu_container'} onClick={(e) => {
        if (!e.target.classList.contains('sidebar_nav_menu_active')) {
          handleClickSidebar();
        }
      }}>
        <nav className={isOpenSidebar ? 'sidebar_nav_menu active' : 'sidebar_nav_menu'} onClick={(e) => e.stopPropagation()}>
          <ul className='sidebar_nav_menu_items' style={{paddingLeft:".5rem"}}>
            <li className='sidebar_close_toggle' onClick={handleClickSidebar} style={{paddingLeft:"2rem"}}>
             <i className="fa-solid fa-xmark"></i>
            </li>
            <ul className="sidebar_item_container">
           
                    <li className="sidebar_nav_items" style={{ paddingTop: "0rem" }}>
                      <ul className="sidebar_nav_items" style={{ display: "flex", gap: "1rem", justifyContent: "flex-start", alignItems: "center", border: "none" }}>
                        <li>
                          <span style={{ backgroundColor: "black", padding: ".9rem 1rem", borderRadius: "50px" }}>
                            <i className="fa-solid fa-user" style={{ fontSize: "1.1rem", color: "white" }}></i>
                          </span>
                        </li>
                        <li style={{ display: "flex", flexDirection: "column", gap: ".2rem", justifyContent: "center" }}>
                          {profileData?.first_name && <span style={{ wordBreak: "break-all" }}>{profileData?.first_name} {profileData?.last_name}</span>}
                          {profileData?.email && <span style={{ wordBreak: "break-all" }}>{profileData?.email}</span>}
                        </li>
                      </ul>
                    </li>
            <Link to="/userdashboardmain/projectsetup"  onClick={handleClickSidebar} className="sidebar_nav_items">
                    <li>
                    <span style={{marginRight:"1rem"}}>Create a new Project</span>   <button className=' btn create_ac_btn'><span><i className="fa-solid fa-plus"></i></span></button>
                    </li>
                </Link>
              <Link to="/userdashboardmain" className="sidebar_nav_items" onClick={handleClickSidebar}> <li> Dashboard  </li> </Link>
              <Link to="/userdashboardmain/reports" className="sidebar_nav_items" onClick={handleClickSidebar}><li>Reports</li></Link>
              <Link to="/userdashboardmain/stores" className="sidebar_nav_items" onClick={handleClickSidebar}> <li>Stores</li></Link>
              <Link to="/userdashboardmain/invoices" className="sidebar_nav_items cart" onClick={handleClickSidebar}> <li>Invoices</li></Link>
              <Link to="/userdashboardmain/profile" className="sidebar_nav_items cart" onClick={handleClickSidebar}> <li>Profile</li></Link>
              <Link to="/userdashboardmain/changepassword" className="sidebar_nav_items cart" onClick={handleClickSidebar}> <li>Change Password</li></Link>
              {/* <li style={{ cursor: "pointer", color: "#ff6f6f", position: "absolute", bottom: "3rem" }} onClick={handleLogout}> <b> Log Out </b></li> */}
              <li onClick={handleLogout} style={{ cursor: "pointer", color: "#ff6f6f"}} className="sidebar_nav_items cart"><b> Log Out </b></li>
            </ul>
          </ul>
        </nav>
      </div>
    </section>
  )
}
