import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";

const BenchmarkingBenefit = ({data}) => {
  const parseOverview = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    return Array.from(doc.querySelectorAll('li')).map(li => li.textContent.trim());
  };

  const benefits = data?.how_it_work ? parseOverview(data?.how_it_work) : [];
  return (
    <div className="py-5" style={{ backgroundColor: "#8DC63F" }}>
      <Container>
        <h2
          className="text-center section-title mb-4"
          style={{ color: "#fff", fontWeight: "bold" }}
        >
          See How It Works
        </h2>
        <Row className="justify-content-center">
          {benefits.map((benefit,index) => (
            <Col key={benefit.id} md={6} lg={4} className="mb-4">
              <Card className="benifit_card p-4 shadow-sm">
                <Card.Body>
                  <div className="d-flex flex-column gap-3">
                    <div
                      className="circle-number  text-white d-flex align-items-center justify-content-center me-3"
                      style={{
                        width: "35px",
                        height: "35px",
                        borderRadius: "50%",
                        fontWeight: "bold",
                        background: "#007DC1",
                      }}
                    >
                      {index+1}
                    </div>
                    {/* <Card.Title className="mb-0" style={{ color: "#007DC1",fontWeight:"bold" }}>
                      {benefit.title}
                    </Card.Title> */}
                    <Card.Text className="text-muted" style={{fontSize:"1.2rem"}}>
                      {benefit}
                    </Card.Text>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default BenchmarkingBenefit;
