import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  locations: [],
};

const locationSlice = createSlice({
  name: "locations",
  initialState,
  reducers: {
    addLocation: (state, action) => {
      state.locations.push(action.payload);
    },
    updateLocation: (state, action) => {
      const index = state.locations.findIndex((loc) => loc.id === action.payload.id);
      if (index !== -1) {
        state.locations[index] = action.payload;
      }
    },
    deleteLocation: (state, action) => {
      state.locations = state.locations.filter((loc) => loc.id !== action.payload);
    },
    setLocations: (state, action) => {
      state.locations = action.payload;
    },
    resetLocations: (state) => {
        state.locations=[];
    }
  },
});

export const { addLocation, updateLocation, deleteLocation, setLocations,resetLocations } = locationSlice.actions;
export default locationSlice.reducer;
