import React, { useEffect,useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import shotcard from "../../../images/shotcard.jpg"
import indicard from "../../../images/indicard.jpg";
import garmincard from "../../../images/garmaincard.png";
import vajorcard from "../../../images/vajorcard.png";
import jmccard from "../../../images/jmccard.jpg";
import piaggiocard from "../../../images/piaggiocard.png";
import piggiocenter from "../../../images/piaggiocenter.jpg";
import piggioside1 from "../../../images/piaggioside1.png";
import piggioside2 from "../../../images/piaggioside2.png";
import "../../../styles/casestudy.css";
import Apparel from './Apparel';
import Automobile from './Automobile';
import cardimg from "../../../images/card.png";
import OnlineServices from './OnlineServices';
import Retail from './Retail';
import Entertainment from './Entertainment';

export default function CaseStudyMain() {
    const [activeTab, setActiveTab] = useState('All Case Studies');
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, []);

    return (
        <>
            <div className='categoryname_banner indiv_blog_heading' style={{ height: "120px" }}>
                <h1 style={{ fontSize: "2.5rem" }}>Case Studies</h1>
            </div>
            <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexWrap:"wrap",gap:"1.2rem",marginTop:"2rem",padding:"0rem 1rem"}} className='casestudy_tab_box'>
               <p onClick={() => setActiveTab('All Case Studies')}>All Case Studies</p>
               <p onClick={() => setActiveTab('Apparel')}>Apparel</p>
               <p onClick={() => setActiveTab('Online Service')}>Online Service</p>
               <p onClick={() => setActiveTab('Automobile')}>Automobile</p>
               <p onClick={() => setActiveTab('Retail')}>Retail</p>
               <p onClick={() => setActiveTab('Entertainment')}>Entertainment</p>
               {/* <p onClick={() => setActiveTab('Healthcare')}>Healthcare</p>
               <p onClick={() => setActiveTab('Food & Bevarage')}>Food & Bevarage</p>
               <p onClick={() => setActiveTab('Real Estate')}>Real Estate</p> */}
            </div>
            {activeTab === 'All Case Studies' && (
            <Container fluid className='homepage_brand_container'>
                <Row className='homepage_brand casestudy_box_container' style={{paddingTop:"1rem"}}>
                    <Col className='homepage_brand_box casestudy_box' style={{ borderRadius: "7px" }}>
                    <Link to="/casestudies/amazon">
                        <div className='homepage_brand_img'>
                            <img src={cardimg} alt="amazonimg" style={{ borderTopLeftRadius: "7px", borderTopRightRadius: "7px",height:"auto" }} />
                        </div>
                        <div className='homepage_brand_text casestudy_brand_text'>
                            <div>
                                <h5 style={{ marginLeft: "1rem",fontWeight:"bold" }}>Amazon</h5>
                                <p style={{ margin: "0rem 1rem" }}>The client wanted to study the Market price of the various television models. This was mainly to understand price gaps in the online and offline market.</p>
                                <div style={{ marginTop: "1rem", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0rem 1rem" }}>
                                    <button className="button btn-md text-center btn-size create_ac_btn">Online Service</button>
                                    <div style={{ border: "2px solid black", padding: "3px 7px", borderRadius: "50%" }}>
                                        <i className="fa-solid fa-arrow-right" style={{ marginTop: "3px", fontSize: "1.2rem" }}></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </Link>
                    </Col>
                    <Col className='homepage_brand_box casestudy_box' style={{ borderRadius: "7px" }}>
                        <Link to="/casestudies/garmin">
                        <div className='homepage_brand_img'>
                            <img src={garmincard} alt="garminimg" style={{ borderTopLeftRadius: "7px", borderTopRightRadius: "7px",height:"auto" }} />
                        </div>
                        <div className='homepage_brand_text casestudy_brand_text'>
                            <div>
                                 <h5 style={{ marginLeft: "1rem",fontWeight:"bold" }}>Garmin</h5>
                                <p style={{ margin: "0rem 1rem" }}>Garmin wanted to check the stock availabiltiy and the product display at the store to understand the reason behind sales count and whether... </p>
                                 <div style={{ marginTop: "1rem", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0rem 1rem" }}>
                                    <button className="button btn-md text-center btn-size create_ac_btn">Apparel</button>
                                    <div style={{ border: "2px solid black", padding: "3px 7px", borderRadius: "50%" }}>
                                        <i className="fa-solid fa-arrow-right" style={{ marginTop: "3px", fontSize: "1.2rem" }}></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </Link>
                    </Col>
                    <Col className='homepage_brand_box casestudy_box' style={{ borderRadius: "7px" }}>
                        <Link to="/casestudies/vajor">
                        <div className='homepage_brand_img'>
                            <img src={vajorcard} alt="vajorimg" style={{ borderTopLeftRadius: "7px", borderTopRightRadius: "7px",height:"auto" }} />
                        </div>
                        <div className='homepage_brand_text casestudy_brand_text'>
                            <div>
                                <h5 style={{ marginLeft: "1rem",fontWeight:"bold" }}>Vajor</h5>
                                <p style={{ margin: "0rem 1rem" }}>The challenge at hand was to ensure that the product presentation within the company's retail outlets aligns with established standards... </p>
                                <div style={{ marginTop: "1rem", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0rem 1rem" }}>
                                    <button className="button btn-md text-center btn-size create_ac_btn">Apparel</button>
                                    <div style={{ border: "2px solid black", padding: "3px 7px", borderRadius: "50%" }}>
                                        <i className="fa-solid fa-arrow-right" style={{ marginTop: "3px", fontSize: "1.2rem" }}></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </Link>
                    </Col>

                </Row>
                <Row className='homepage_brand casestudy_box_container' style={{paddingTop:"1rem"}}>
                    <Col className='homepage_brand_box casestudy_box' style={{ borderRadius: "7px" }}>
                    <Link to="/casestudies/jmc">
                        <div className='homepage_brand_img'>
                            <img src={jmccard} alt="jmcimg" style={{ borderTopLeftRadius: "7px", borderTopRightRadius: "7px",height:"auto" }} />
                        </div>
                        <div className='homepage_brand_text casestudy_brand_text'>
                            <div>
                                <h5 style={{ marginLeft: "1rem",fontWeight:"bold" }}>JMC</h5>
                                <p style={{ margin: "0rem 1rem" }}>The challenge at hand was to elevate JMC's standing and performance within the Indore jewelry market. To achieve this objective, the focus was on...</p>
                                <div style={{ marginTop: "1rem", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0rem 1rem" }}>
                                    <button className="button btn-md text-center btn-size create_ac_btn">Retail</button>
                                    <div style={{ border: "2px solid black", padding: "3px 7px", borderRadius: "50%" }}>
                                        <i className="fa-solid fa-arrow-right" style={{ marginTop: "3px", fontSize: "1.2rem" }}></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </Link>
                    </Col>
                    <Col className='homepage_brand_box casestudy_box' style={{ borderRadius: "7px" }}>
                        <Link to="/casestudies/indyverse">
                        <div className='homepage_brand_img'>
                            <img src={indicard} alt="indyverseimg" style={{ borderTopLeftRadius: "7px", borderTopRightRadius: "7px",height:"auto" }} />
                        </div>
                        <div className='homepage_brand_text casestudy_brand_text'>
                            <div>
                                 <h5 style={{ marginLeft: "1rem",fontWeight:"bold" }}>Indyverse</h5>
                                <p style={{ margin: "0rem 1rem" }}>The challenge at hand was to bolster sales by leveraging customer reviews and ratings as a means to enhance brand visibility in the target market. </p>
                                 <div style={{ marginTop: "1rem", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0rem 1rem" }}>
                                    <button className="button btn-md text-center btn-size create_ac_btn" style={{paddiing:"1px 3px"}}>Online Service</button>
                                    <div style={{ border: "2px solid black", padding: "3px 7px", borderRadius: "50%" }}>
                                        <i className="fa-solid fa-arrow-right" style={{ marginTop: "3px", fontSize: "1.2rem" }}></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </Link>
                    </Col>
                    <Col className='homepage_brand_box casestudy_box' style={{ borderRadius: "7px" }}>
                        <Link to="/casestudies/shott">
                        <div className='homepage_brand_img'>
                            <img src={shotcard} alt="shottimg" style={{ borderTopLeftRadius: "7px", borderTopRightRadius: "7px",height:"auto" }} />
                        </div>
                        <div className='homepage_brand_text casestudy_brand_text'>
                            <div>
                                <h5 style={{ marginLeft: "1rem",fontWeight:"bold" }}>Shott</h5>
                                <p style={{ margin: "0rem 1rem" }}>Shott's challenge was to effectively benchmark against 22 competitors in the combined markets of Pune and Hyderabad.Their objective was to... </p>
                                <div style={{ marginTop: "1rem", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0rem 1rem" }}>
                                    <button className="button btn-md text-center btn-size create_ac_btn">Entertainment</button>
                                    <div style={{ border: "2px solid black", padding: "3px 7px", borderRadius: "50%" }}>
                                        <i className="fa-solid fa-arrow-right" style={{ marginTop: "3px", fontSize: "1.2rem" }}></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </Link>
                    </Col>

                </Row>
                <Row className='homepage_brand casestudy_box_container' style={{paddingTop:"1rem"}}>
                    <Col className='homepage_brand_box casestudy_box' style={{ borderRadius: "7px" }} md="4">
                    <Link to="/casestudies/piaggio">
                        <div className='homepage_brand_img'>
                            <img src={piaggiocard} alt="piaggioimg" style={{ borderTopLeftRadius: "7px", borderTopRightRadius: "7px",height:"auto" }} />
                        </div>
                        <div className='homepage_brand_text casestudy_brand_text'>
                            <div>
                                <h5 style={{ marginLeft: "1rem",fontWeight:"bold" }}>Piaggio</h5>
                                <p style={{ margin: "0rem 1rem" }}>Piaggio opted for sales process audits to check the product demo and product pitch by the staff which in turn would help to assess staff knowledge...</p>
                                <div style={{ marginTop: "1rem", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0rem 1rem" }}>
                                    <button className="button btn-md text-center btn-size create_ac_btn">Automobile</button>
                                    <div style={{ border: "2px solid black", padding: "3px 7px", borderRadius: "50%" }}>
                                        <i className="fa-solid fa-arrow-right" style={{ marginTop: "3px", fontSize: "1.2rem" }}></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </Link>
                    </Col>
                    </Row>
            </Container>
            )}
            {activeTab === 'Apparel' && (
                <Apparel/>
    )}
     {activeTab === 'Automobile' && (
               <Automobile/>
    )}
     {activeTab === 'Online Service' && (
              <OnlineServices/>
    )}
     {activeTab === 'Retail' && (
             <Retail/>
    )}
      {activeTab === 'Entertainment' && (
             <Entertainment/>
    )}
        </>
    )
}
