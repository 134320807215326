import React, { useEffect } from 'react'
import ProductUpperPart from './ProductUpperPart'
import "../../styles/pricing.css"
import BenchmarkingBenefit from './BenchmarkingBenefit'
import SuggestedProduct from './SuggestedProduct'
import { useLocation } from 'react-router-dom'
import { useFetchinFunction } from '../../utils/hooks/useFetchinFunction'
import Shimmer from '../shimmerComponents/Shimmer'
import ProductImages from './ProductImages'
import base_url from '../../utils/config'

export default function NewIndividualProduct() {
  const location = useLocation();
  const id = location.state?.id;
  const categoryId = localStorage.getItem("selectedCategoryId"); // need this one for direct url sharing, as here categoryId is fixed, so, hardcoding, otherwise refer to individualProduct.js
  const arr = location.pathname.split("/")
  const producturl = arr[arr.length - 1];
  const {data,setData,getApiData,isLoading} = useFetchinFunction();

  useEffect(()=>{
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    if(id){
      getApiData(`manager/public_solution/${id}/full_details`)
    }
    if (categoryId && !id) { // for those scenerio where need to copy the link of a product and share with someone
      console.log("through direct url in product page")
      getProductList(base_url + "manager/public_category/" + categoryId + "/solution_detail");
    }
  },[id])

  const getProductList = async (url) => {
    try {
      const response = await fetch(url);
      if (response.ok) {
        const result = await response.json();
        // console.log("categoryproductpage", result[0].solutions.find(item => item?.url_structure === producturl))
        const requiredProduct = result[0].solutions.find(item => item?.url_structure === producturl)
        const productId = requiredProduct?.id;
        if (productId) {
          getApiData(`manager/public_solution/${productId}/full_details`);
        }
      } else {
        console.error('API request failed:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('An error occurred while fetching the API:', error);
    }
  }
  return (
    isLoading ? 
    <Shimmer/>
    :
   <>
   <ProductUpperPart data={data[0]} />
   <ProductImages data={data[0]}/>
   <BenchmarkingBenefit data={data[0]}/>
   <SuggestedProduct existingProductId={id}/>
   </>
  )
}
