import React, { useContext } from "react";
import { Button, Container } from "react-bootstrap";
import { AppContext } from "../../App";
import { Jodit } from "jodit-react";
import { useNavigate } from "react-router-dom";



const SuggestedProduct = ({existingProductId}) => {
  const navigate = useNavigate()
   const { popularSolutionDataMain} = useContext(AppContext);
   const handleNavigation = (product)=>{
    navigate(`/${product?.url_structure}`,{state:{id:product?.id}})
   }
  return (
    <Container className="py-5">
      <h2
        className="text-center mb-4"
        style={{ color: "#0D507D"}}
      >
       Suggested Products
      </h2>
      <div className="servey_card_wrapper">
        {popularSolutionDataMain.filter(product=>product?.id !== existingProductId).map((product) => (
          <div className="servey_card">
            <img src={product?.attachments[0]?.preview_url} className="servey_card_img" alt={product?.attachments[0]?.file_name}/>
            <div className="servey_card_body">
              <div>
                <h2 style={{ color: "#0D507D" }} className="heading-suggested-product">
                {product?.name}
                </h2>
                <h4
                  style={{ fontWeight: "400" }}
                  className="text-muted reading_card_title-suggested-product"
                >
                  {Jodit.modules.Helpers.stripTags(product?.short_description)}
                </h4>
              </div>
            </div>
            <button className="button w-full button-primary" onClick={()=>handleNavigation(product)}>
              Check Product Details
            </button>
          </div>
        ))}
      </div>
      {/* <div className="text-center mt-4">
        <p style={{ fontSize: "14px", color: "#666" }}>
          Affordable and reliable for all small businesses.
        </p>
        <h3 style={{ color: "#0D507D" }}>
          INR.120000{" "}
          <span style={{ fontSize: "14px", color: "#666" }}>
            /Month + 18% GST
          </span>
        </h3>
        <Button
          variant="primary"
          style={{ backgroundColor: "#007DC1", padding: "10px 20px" }}
        >
          Buy Now
        </Button>
      </div> */}
    </Container>
  );
};

export default SuggestedProduct;
