import { Container, Stack } from "react-bootstrap";
import "../../styles/pricing.css";

const DATA = [
  {
    icon: "/icons/check.svg",
    title: "Step 1",
    des: "Select your focus area",
  },
  {
    icon: "/icons/connect_without_contact.svg",
    title: "Step 2",
    des: "Identify the right market research package",
  },
  {
    icon: "/icons/map.svg",
    title: "Step 3",
    des: "Tell us the location where you want our insights from",
  },
  {
    icon: "/icons/record_voice.svg",
    title: "Step 4",
    des: "The field agent will visit these locations, review and provide feedback",
  },
  {
    icon: "/icons/timeline.svg",
    title: "Step 5",
    des: " A detailed report will be submitted to our dashboard for review",
  },
];

const HowItWorks = () => {
  return (
    <Container className="py-4 py-lg-5 mt-2">
      <Stack className="flex-column ">
        <h2 className="text-center section-title">See How it works</h2>
        <p className="text-center">
          A Comprehensive Guide to Customer Experience Evaluation
        </p>
      </Stack>
      <div className="mt-4 how_it_works_items lg-mt-5">
        {DATA.map((item, index) => (
          <div key={index} className="mt-lg-4">
            <div className="d-flex align-items-center gap-1">
              <div
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  background: "#007DC1",
                }}
                className="d-flex align-items-center justify-content-center"
              >
                <img
                  src={item.icon}
                  alt={item.title}
                  style={{
                    width: "24px",
                    height: "24px",
                  }}
                />
              </div>
              <div
                style={{
                  background:
                    "linear-gradient(90deg, #007DC1 28.5%, rgba(255, 255, 255, 0.00) 100%)",
                  height: "4px",
                  flex: "1",
                  width: "100%",
                }}
              ></div>
            </div>
            <div className="d-flex flex-column mt-3">
              <h2 style={{ color: "#003C5D" }} className="heading-3">
                {item?.title}
              </h2>
              <p style={{ color: "#9CA3AF" }} className="pera">
                {item?.des}
              </p>
            </div>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default HowItWorks;
