import { useEffect, useState } from "react";
import img from "../../images/3.png";
import "bootstrap/dist/css/bootstrap.min.css"; // Ensure Bootstrap is imported

export default function ProductImages({data}) {
  const [activeIndex, setActiveIndex] = useState(0);

  const images = [img, img, img];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % data?.attachments?.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [data?.attachments?.length]);

  return (
    <div style={{marginBottom:"2rem"}} className="main container">
      <div className="row justify-content-center gap-2">
        {data?.attachments.map((imageSrc, index) => (
          <div
            key={index}
            className="col-auto"
            style={{
              display: "inline-block",
              padding: "0 8px",
              transition: "transform 0.2s ease-in-out, filter 0.2s ease-in-out",
              transform: index === activeIndex ? "scale(1)" : "scale(1)",
              filter: index === activeIndex ? "none" : "grayscale(100%)",
            }}
          >
            <img
              src={imageSrc?.preview_url}
              alt={`Case Study ${index + 1}`}
              className="img-fluid"
              style={{
                width: index === activeIndex ? "380px" : "340px",
                height: index === activeIndex ? "250px" : "200px",
                marginTop: index === activeIndex ? "0px" : "20px",
                borderRadius: "5px",
              }}
            />
          </div>
        ))}
      </div>
      <style>
        {`
          @keyframes slide {
            0% { transform: translateX(0); }
          }
          .main {
            overflow: hidden;
            width: 100%;
          }
        `}
      </style>
    </div>
  );
}
