import React from 'react';
import Marquee from 'react-fast-marquee';
import img1 from '../../images/logoSlider/Amazon.png';
import img2 from '../../images/logoSlider/Asset 50.png';
import img3 from '../../images/logoSlider/Caratlane-logo.png';
import img4 from '../../images/logoSlider/KPMG.png';
import img5 from '../../images/logoSlider/Manyavar.png';
import img6 from '../../images/logoSlider/PWC.png';
import img7 from '../../images/logoSlider/Porsche.png';
import img8 from '../../images/logoSlider/Vivo.png';
import img9 from '../../images/logoSlider/Cars_24.png';
import img10 from '../../images/logoSlider/garmin_logo.png';
import img11 from '../../images/logoSlider/hyundai.png';
import img12 from '../../images/logoSlider/oyo_logo.png';
import img13 from '../../images/logoSlider/whitehatjr_logo.png';
import img14 from '../../images/logoSlider/zomato_logo.png';
import "../../styles/globalpartner.css";

const logoArr = [
    { name: img1, altName: "amazon" },
    { name: img2, altName: "TCI" },
    { name: img3, altName: "caratelane" },
    { name: img4, altName: "KPMG" },
    { name: img5, altName: "manyavar" },
    { name: img6, altName: "PWC" },
    { name: img7, altName: "porsche" },
    { name: img8, altName: "vivo" },
    { name: img9, altName: "cars24" },
    { name: img10, altName: "garmin" },
    { name: img11, altName: "hyundai" },
    { name: img12, altName: "OYO" },
    { name: img13, altName: "whitehatjr" },
    { name: img14, altName: "zomato" },
];

function HomeGlobalPartner() {
    return (
        <div className="container mt-5">
            <div className="text-center mb-4">
                <h2 className="text-center section-title">Global Partners</h2>
                <p className="text-muted">Trusted by the world's most customer-focused companies</p>
            </div>

            <div className="d-flex justify-content-center align-items-center">
                <Marquee direction="left" speed={100} delay={5} className='logosliderbox'>
                    {logoArr.map(item => (
                        <div className="image_wrapper mx-3" key={item.altName}>
                            <img src={item.name} alt={item.altName} style={{ height: '50px' }} />
                        </div>
                    ))}
                </Marquee>
            </div>
        </div>
    );
}

export default HomeGlobalPartner;
