import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col, Alert, Modal } from "react-bootstrap";
import "../../styles/login.css";
import base_url from "../../utils/config";

export function EmailVerification({ userId,responseMail }) {
  const navigate = useNavigate();
  const [hasError, setHasError] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [otp, setOTP] = useState(['', '', '', '']);
  const otpInputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [otpErrorMessage, setOtpErrorMessage] = useState("");
  const [otpShowAlert, setOtpShowAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  

  const handleOTPInput = (index, event) => {
    const value = event.target.value;
    const newOTP = [...otp];
    if (/^[0-9]*$/.test(value) && index < 3) {
      newOTP[index] = value;
      setOTP(newOTP);
      otpInputRefs[index + 1].current.focus();
    } else if (/^[0-9]*$/.test(value) && index === 3) {
      newOTP[index] = value;
      setOTP(newOTP);
    }
  };
  const handleInputKeyDown = (index, event) => {
    if (event.key === 'Backspace') {
      if (!otp[index] && index > 0) {
        const newOTP = [...otp];
        newOTP[index - 1] = '';
        setOTP(newOTP);
        otpInputRefs[index - 1].current.focus();
      }
    }
  };

  function handleOTP() {
    setLoading(true);
    const enteredOtp = otp.join("").toString();
    const requestData = {
      user: userId,
      otp: enteredOtp,
    };
    fetch(base_url + "client/market_place/verify_email_by_otp", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestData)
    })
      .then(response => response.json())
      .then(data => {
        setHasError(false);
        setOtpShowAlert(true);
        setTimeout(() => {
          setOtpShowAlert(false);
          setLoading(false);
      }, 1700)
        setOtpErrorMessage(data);
        if (data && data.client_dashboard_data.client_profile_info.user_id) {
          localStorage.setItem('user_id', data.client_dashboard_data.client_profile_info.user_id);
          localStorage.setItem('token', data.token);
          localStorage.setItem('client_id', data.client_dashboard_data.client_profile_info.client_id);
          setModalShow(false);
          navigate("/userdashboardmain");
        }
      })
      .catch(error => {
        console.error('Error:', error);
      }).finally(() => {
        setLoading(false);
    });
  }
  return (
    <>
      {otpShowAlert && (<Alert variant={otpErrorMessage.detail === "OTP Verified !! Login Successfully" ? "success" : "danger"} onClose={() => setOtpShowAlert(false)} dismissible>
        {otpErrorMessage.detail}
      </Alert>
      )}
      <h4>Client Registered Successfully. </h4>
      <h5 style={{fontSize:"1.4rem"}}><b> Please Check <span style={{color:"green"}}>{responseMail}</span> & Enter the OTP for verification</b></h5>
      <div className="otp_box_container">
        {otp.map((digit, index) => (
          <input
            className="otp_box"
            key={index}
            type="text"
            maxLength="1"
            value={digit}
            onChange={(e) => handleOTPInput(index, e)}
            onKeyDown={(e) => handleInputKeyDown(index, e)}
            ref={otpInputRefs[index]}
          />
        ))}
      </div>
      {loading ?

        <button className="btn mb-5 btn-md text-center btn-size create_ac_btn" type="button" disabled style={{ fontSize: "1rem", background: "#007DC1", color: "white", }}>
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: ".5rem" }}></span>
          Loading...
        </button>

        :

        <div className="btn mb-5 btn-md text-center btn-size create_ac_btn"
          onClick={handleOTP}
        >Enter otp</div>
      }
    </>
  )
}

function Login() {
  const navigate = useNavigate();
  const [routeDirection, setRouteDirection] = useState(false);
  const [userId, SetUserId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });
  const [errorMessages, setErrorMessages] = useState({
    usernameError: '',
    passwordError: '',
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [hasError, setHasError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [reponseMail, setResponseMail] = useState("");

  useEffect(()=>{
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  },[])
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    setErrorMessages(prevErrors => ({
      ...prevErrors,
      [`${name}Error`]: ''
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(false);
    if (!formData.username) {
      setErrorMessages(prevErrors => ({
        ...prevErrors,
        usernameError: "email can't be empty"
      }));
      setHasError(true)
      setTimeout(() => {
        setErrorMessages(prevErrors => ({
          ...prevErrors,
          usernameError: ""
        }));
        setHasError(false);
      }, 1200)
    }

    if (!formData.password) {
      setErrorMessages(prevErrors => ({
        ...prevErrors,
        passwordError: "Password can't be empty"
      }));
      setHasError(true)
      setTimeout(() => {
        setErrorMessages(prevErrors => ({
          ...prevErrors,
          passwordError: ""
        }));
        setHasError(false);
      }, 1200)
    }

    if (formData.username && formData.password) {
      setLoading(true);
      const requestData = {
        username: formData.username,
        password: formData.password,
      };
      fetch(base_url + "client/market_place/login_api", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData)
      })
        .then(response => response.json())
        .then(data => {
          setResponseMail(data?.email);
          setHasError(false);
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
            // setLoading(false);
          }, 1700)
          setErrorMessage(data);
          SetUserId(data.user);
          if (data.details === "OTP is Shared On Your Email !!") {
            setModalShow(true);
          }
          if (data && data.client_dashboard_data.client_profile_info.user_id) {
            localStorage.setItem('user_id', data.client_dashboard_data.client_profile_info.user_id);
            localStorage.setItem('token', data.token);
            localStorage.setItem('client_id', data.client_dashboard_data.client_profile_info.client_id);
            // localStorage.setItem('client_store_id', data.client_dashboard_data.client_profile_info.id);
            setTimeout(() => {
              navigate("/userdashboardmain");
            }, 1000);
          }
          setFormData({
            username: '',
            password: '',
          });
        })
        .catch(error => {
          console.error('Error:', error);
        }).finally(() => {
          setLoading(false);
        })
    }
  }

  return (
    <>
      <div className="main-wrapper login-page">
        {showAlert && (<Alert variant={errorMessage.detail === "Login Successfully" ? "success" : "danger"} onClose={() => setShowAlert(false)} dismissible style={{ textAlign: "center" }}>
          {errorMessage.detail}
        </Alert>
        )}
        <div>
          <div className="login_logo">
            <Link to="/">
              <img
                src="https://marketplace.floorwalk.in/logo.png"
                alt=""
              />
            </Link>
            <h6>Reasearch is formalized curiosity </h6>
          </div>
        </div>

        <section className="login-area">
          <Container>
            <form className="form" onSubmit={handleSubmit}>
              <Row>
                <Col md="12" className="d-flex justify-content-center">
                  <div className="login-box">
                    <p className="login_form_control">
                      <label>Email<span>*</span></label>
                      <input
                        type="text"
                        placeholder="Email ID"
                        name='username'
                        value={formData.username}
                        onChange={handleChange}
                        className={hasError ? "login_input error_border" : "login_input success_border"}
                      />
                      {errorMessages.usernameError && (
                        <span className="error_message">{errorMessages.usernameError}</span>
                      )}
                    </p>
                    <p className="login_form_control">
                      <label htmlFor="login_password">Password<span>*</span></label>
                      <input
                        type="password"
                        placeholder="*******"
                        name='password'
                        value={formData.password}
                        onChange={handleChange}
                        className={hasError ? "login_input error_border" : "login_input success_border"}
                      />
                      {errorMessages.passwordError && (
                        <span className="error_message">{errorMessages.passwordError}</span>
                      )}
                    </p>

                    {loading ?
                      <div className="login_btn_container">
                        <button className="loginButton" type="button" disabled style={{ fontSize: ".8rem" }}>
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: ".5rem" }}></span>
                          Loading...
                        </button>
                      </div>
                      :
                      <div className="login_btn_container">
                        <button
                          className="loginButton"
                        >
                          Log In
                        </button>
                      </div>
                    }
                    <div className="save-forget-password d-flex">
                      <div className="forget-password">
                        <Link
                          to="/accountinfo/forgotpassword"
                        >
                          Forget Password?
                        </Link>
                      </div>

                      <div className="not_account-btn">
                        <p>
                          <Link to="/accountinfo/registration">
                            Create new account
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </form>
            <Modal
              show={modalShow}
              centered
              size='lg'
              style={{ marginTop: '0px', background: "rgba(0,0,0,.7)", height: "100vh" }}
              // scrollable={true}
              className='modal-dialog-scrollable p-6 modal_mobile'
              onHide={() => setModalShow(false)}
            >
              <div
                style={{
                  width: '100%',
                  height: "50vh",
                  padding: "2rem 3rem",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  border: 'none',
                }}
              >
                 <div style={{ position: "absolute", right: "2rem", top: "2rem", cursor: "pointer" }} onClick={() => setModalShow(false)}><i className="fa-solid fa-x"></i></div>
                <EmailVerification userId={userId} responseMail={reponseMail}/>
              </div>
            </Modal>
          </Container>
        </section>
      </div>
    </>
  );
}

export default Login;
