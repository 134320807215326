import React from 'react';
import { Col,Row,Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import cardimg from "../../../images/card.png";
import indyversecard from "../../../images/indicard.jpg";

export default function OnlineServices() {
  return (
    <Container fluid className='homepage_brand_container'>
                <Row className='homepage_brand' style={{paddingTop:"1rem"}}>
                <Col className='homepage_brand_box casestudy_box' style={{ borderRadius: "7px" }} md="4">
                    <Link to="/casestudies/amazon">
                        <div className='homepage_brand_img'>
                            <img src={cardimg} alt="manyvarimg" style={{ borderTopLeftRadius: "7px", borderTopRightRadius: "7px",height:"auto" }} />
                        </div>
                        <div className='homepage_brand_text casestudy_brand_text'>
                            <div>
                                <h5 style={{ marginLeft: "1rem",fontWeight:"bold" }}>Amazon</h5>
                                <p style={{ margin: "0rem 1rem" }}>The client wanted to study the Market price of the various television models. This was mainly to understand price gaps in the online and offline market.</p>
                                <div style={{ marginTop: "1rem", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0rem 1rem" }}>
                                    <button className="button btn-md text-center btn-size create_ac_btn">Online Services</button>
                                    <div style={{ border: "2px solid black", padding: "3px 7px", borderRadius: "50%" }}>
                                        <i className="fa-solid fa-arrow-right" style={{ marginTop: "3px", fontSize: "1.2rem" }}></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </Link>
                    </Col>
                    <Col className='homepage_brand_box casestudy_box' style={{ borderRadius: "7px" }} md="4">
                        <Link to="/casestudies/indyverse">
                        <div className='homepage_brand_img'>
                            <img src={indyversecard} alt="indyverseimg" style={{ borderTopLeftRadius: "7px", borderTopRightRadius: "7px",height:"auto" }} />
                        </div>
                        <div className='homepage_brand_text casestudy_brand_text'>
                            <div>
                                 <h5 style={{ marginLeft: "1rem",fontWeight:"bold" }}>Indyverse</h5>
                                <p style={{ margin: "0rem 1rem" }}>The challenge at hand was to bolster sales by leveraging customer reviews and ratings as a means to enhance brand visibility in the target market. </p>
                                 <div style={{ marginTop: "1rem", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0rem 1rem" }}>
                                    <button className="button btn-md text-center btn-size create_ac_btn">Online Services</button>
                                    <div style={{ border: "2px solid black", padding: "3px 7px", borderRadius: "50%" }}>
                                        <i className="fa-solid fa-arrow-right" style={{ marginTop: "3px", fontSize: "1.2rem" }}></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </Link>
                    </Col>
                </Row>
                
            </Container>
  )
}
