import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate,useLocation } from 'react-router-dom';
import { clearCart } from '../../utils/cartSlice';
import { clearStoreData, removeFile } from '../../utils/orderdataslice';
import "../../styles/dashboardSidebar.css";
import floorwalklogo from "../../../src/images/floorwalk_logo.png";
import GoogleTranslateWidget from "../GoogleTranslateWidget.js";
import { resetLocations } from '../../utils/locationSlice.js';
import { removeAuthData } from '../../utils/authSlice.js';


export default function DashboardSidebar() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const [selectedMenu, setSelectedMenu] = useState("Dashboard");

    useEffect(() => {
        const currentPath = location.pathname;
        if (currentPath.includes('reports')) {
            setSelectedMenu('Reports');
        }
        if(location.state === "profile"){
            setSelectedMenu("Profile")
        }
    }, [location]);
    
    function handleLogout() {
        dispatch(clearCart());
        dispatch(clearStoreData());
        dispatch(removeFile());
        dispatch(resetLocations());
        dispatch(removeAuthData());
        localStorage.removeItem('user_id');
        localStorage.removeItem('token');
        localStorage.removeItem('client_id');
        localStorage.removeItem('orderId');
        navigate("/");
    }
    function handleLogo() {
        // dispatch(clearCart());
        // dispatch(clearStoreData());
        // dispatch(removeFile());
        // localStorage.removeItem('orderId');
        window.location.reload();
      }

    const handleMenuClick = (menuName) => {
        setSelectedMenu(menuName);
    };

    return (
        <div className='dash_sidebar_container' >
            	
            <div className="dash_side_logo" onClick={handleLogo}>
                <Link to="/">
                    <img
                        src={floorwalklogo}
                        alt=""
                    />
                </Link>
            </div>
            <ul className='dash_side_list_box'>
               <ul style={{ listStyleType: 'none', display: 'flex', alignItems: 'center',paddingLeft:"0rem" }}>
                    <Link to="/userdashboardmain/projectsetup" style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
                    <li>
                        <button className='button create_ac_btn' style={{padding:".3rem .5rem"}}>
                        <span><i className="fa-solid fa-plus"></i></span>
                        </button>
                    </li>
                    <li style={{ marginLeft: '20px' }}>
                        <GoogleTranslateWidget />
                    </li>
                    </Link>
                </ul>
               
                <Link to="/userdashboardmain"> <li
                    className={selectedMenu === 'Dashboard' ? 'selected-menu' : ''}
                    onClick={() => handleMenuClick('Dashboard')}
                >
                    <i className="fa-solid fa-clipboard"></i>Dashboard
                </li>
                </Link>
                <li className='dash_side_list_head'>ANALYZE</li>
                <Link to="/userdashboardmain/reports">
                    <li
                        className={selectedMenu === 'Reports' ? 'selected-menu' : ''}
                        onClick={() => handleMenuClick('Reports')}
                    >
                        <i className="fa-solid fa-clipboard"></i>Reports
                    </li>
                </Link>
                {/* <li className='dash_side_list_head'>MANAGE</li>
                <Link to="/userdashboardmain/stores">
                    <li
                        className={selectedMenu === 'Stores' ? 'selected-menu' : ''}
                        onClick={() => handleMenuClick('Stores')}
                    >
                        <i className="fa-solid fa-clipboard"></i>Stores
                    </li>
                </Link> */}
                <li className='dash_side_list_head'>PAYMENTS</li>
                <Link to="/userdashboardmain/invoices">
                    <li
                        className={selectedMenu === 'Invoices' ? 'selected-menu' : ''}
                        onClick={() => handleMenuClick('Invoices')}
                    >
                        <i className="fa-solid fa-clipboard"></i>Invoices
                    </li>
                </Link>
                <li className='dash_side_list_head'>User Details</li>
                <Link to="/userdashboardmain/profile"> <li
                    className={selectedMenu === 'Profile' ? 'selected-menu' : ''}
                    onClick={() => handleMenuClick('Profile')}
                ><i className="fa-solid fa-clipboard"></i>Profile</li></Link>
                <Link to="/userdashboardmain/changepassword"> <li
                    className={selectedMenu === 'Change Password' ? 'selected-menu' : ''}
                    onClick={() => handleMenuClick('Change Password')}
                ><i className="fa-solid fa-clipboard"></i>Change Password</li></Link>
                <li style={{ cursor: "pointer", color: "#ff6f6f", marginTop:"1.2rem"}} className='dash_side_list_head' onClick={handleLogout}> <b> Log Out </b></li>
            </ul>
        </div>
    )
}
