export const IconBadge = ({ icon }) => (
    <div className="icon-badge">
      {icon === 'icon1' && (
         <svg
         xmlns="http://www.w3.org/2000/svg"
         width={24}
         height={24}
         viewBox="0 0 24 24"
         fill="none"
       >
         <g clipPath="url(#clip0_1_3585)">
           <path
             d="M1 21H13V23H1V21ZM5.24 8.07L8.07 5.24L22.21 19.38L19.38 22.21L5.24 8.07ZM12.32 1L17.98 6.66L15.15 9.49L9.49 3.83L12.32 1ZM3.83 9.48L9.49 15.14L6.66 17.97L1 12.31L3.83 9.48Z"
             fill="#007DC1"
           />
         </g>
         <defs>
           <clipPath id="clip0_1_3585">
             <rect width={24} height={24} fill="white" />
           </clipPath>
         </defs>
       </svg>
      )}
      {icon === 'icon2' && (
           <svg
           xmlns="http://www.w3.org/2000/svg"
           width={24}
           height={24}
           viewBox="0 0 24 24"
           fill="none"
         >
           <g clipPath="url(#clip0_1_3570)">
             <path
               d="M9 13C11.2091 13 13 11.2091 13 9C13 6.79086 11.2091 5 9 5C6.79086 5 5 6.79086 5 9C5 11.2091 6.79086 13 9 13Z"
               fill="#007DC1"
             />
             <path
               d="M9 15C6.33 15 1 16.34 1 19V21H17V19C17 16.34 11.67 15 9 15ZM16.76 5.36L15.08 7.05C15.92 8.23 15.92 9.76 15.08 10.94L16.76 12.63C18.78 10.61 18.78 7.56 16.76 5.36ZM20.07 2L18.44 3.63C21.21 6.65 21.21 11.19 18.44 14.37L20.07 16C23.97 12.11 23.98 6.05 20.07 2Z"
               fill="#007DC1"
             />
           </g>
           <defs>
             <clipPath id="clip0_1_3570">
               <rect width={24} height={24} fill="white" />
             </clipPath>
           </defs>
         </svg>
      )}
      {icon === "icon3" && (
          <svg
          xmlns="http://www.w3.org/2000/svg"
          width={22}
          height={17}
          viewBox="0 0 22 17"
          fill="none"
        >
          <path
            d="M20 5C18.55 5 17.74 6.44 18.07 7.51L14.52 11.07C14.22 10.98 13.78 10.98 13.48 11.07L10.93 8.52C11.27 7.45 10.46 6 9 6C7.55 6 6.73 7.44 7.07 8.52L2.51 13.07C1.44 12.74 0 13.55 0 15C0 16.1 0.9 17 2 17C3.45 17 4.26 15.56 3.93 14.49L8.48 9.93C8.78 10.02 9.22 10.02 9.52 9.93L12.07 12.48C11.73 13.55 12.54 15 14 15C15.45 15 16.27 13.56 15.93 12.48L19.49 8.93C20.56 9.26 22 8.45 22 7C22 5.9 21.1 5 20 5Z"
            fill="#007DC1"
          />
          <path
            d="M14 6L14.94 3.93L17 3L14.94 2.07L14 0L13.08 2.07L11 3L13.08 3.93L14 6Z"
            fill="#007DC1"
          />
          <path
            d="M2.5 8L3 6L5 5.5L3 5L2.5 3L2 5L0 5.5L2 6L2.5 8Z"
            fill="#007DC1"
          />
        </svg>
      )}
      {icon === "icon4" && (
         <svg
         xmlns="http://www.w3.org/2000/svg"
         width={24}
         height={24}
         viewBox="0 0 24 24"
         fill="none"
       >
         <g clipPath="url(#clip0_1_3585)">
           <path
             d="M1 21H13V23H1V21ZM5.24 8.07L8.07 5.24L22.21 19.38L19.38 22.21L5.24 8.07ZM12.32 1L17.98 6.66L15.15 9.49L9.49 3.83L12.32 1ZM3.83 9.48L9.49 15.14L6.66 17.97L1 12.31L3.83 9.48Z"
             fill="#007DC1"
           />
         </g>
         <defs>
           <clipPath id="clip0_1_3585">
             <rect width={24} height={24} fill="white" />
           </clipPath>
         </defs>
       </svg>
      )}
      {icon==="icon5" && (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
      >
        <g clipPath="url(#clip0_1_3662)">
          <path
            d="M12 1L3 5V11C3 16.55 6.84 21.74 12 23C17.16 21.74 21 16.55 21 11V5L12 1ZM14.5 12.59L15.4 16.47L12 14.42L8.6 16.47L9.5 12.6L6.5 10.01L10.46 9.67L12 6.02L13.54 9.66L17.5 10L14.5 12.59Z"
            fill="#007DC1"
          />
        </g>
        <defs>
          <clipPath id="clip0_1_3662">
            <rect width={24} height={24} fill="white" />
          </clipPath>
        </defs>
      </svg>
      )}
      {icon==="icon6" && (
         <svg
         xmlns="http://www.w3.org/2000/svg"
         width={24}
         height={24}
         viewBox="0 0 24 24"
         fill="none"
       >
         <g clipPath="url(#clip0_1_3695)">
           <path
             d="M11 14H9C9 9.03 13.03 5 18 5V7C14.13 7 11 10.13 11 14ZM18 11V9C15.24 9 13 11.24 13 14H15C15 12.34 16.34 11 18 11ZM7 4C7 2.89 6.11 2 5 2C3.89 2 3 2.89 3 4C3 5.11 3.89 6 5 6C6.11 6 7 5.11 7 4ZM11.45 4.5H9.45C9.21 5.92 7.99 7 6.5 7H3.5C2.67 7 2 7.67 2 8.5V11H8V8.74C9.86 8.15 11.25 6.51 11.45 4.5ZM19 17C20.11 17 21 16.11 21 15C21 13.89 20.11 13 19 13C17.89 13 17 13.89 17 15C17 16.11 17.89 17 19 17ZM20.5 18H17.5C16.01 18 14.79 16.92 14.55 15.5H12.55C12.75 17.51 14.14 19.15 16 19.74V22H22V19.5C22 18.67 21.33 18 20.5 18Z"
             fill="#007DC1"
           />
         </g>
         <defs>
           <clipPath id="clip0_1_3695">
             <rect width={24} height={24} fill="white" />
           </clipPath>
         </defs>
       </svg>
      )}
      {icon==="icon7" && (
         <svg
         xmlns="http://www.w3.org/2000/svg"
         width={24}
         height={24}
         viewBox="0 0 24 24"
         fill="none"
       >
         <g clipPath="url(#clip0_1_3623)">
           <path
             d="M21 3H3C1.9 3 1 3.9 1 5V19C1 20.1 1.9 21 3 21H21C22.1 21 23 20.1 23 19V5C23 3.9 22.1 3 21 3ZM21 19H3V5H21V19ZM11 12H20V18H11V12Z"
             fill="#007DC1"
           />
         </g>
         <defs>
           <clipPath id="clip0_1_3623">
             <rect width={24} height={24} fill="white" />
           </clipPath>
         </defs>
       </svg>
      )}
      {icon==="icon8" && (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
      >
        <g clipPath="url(#clip0_1_3641)">
          <path
            d="M21 3L3 10.53V11.51L9.84 14.16L12.48 21H13.46L21 3Z"
            fill="#007DC1"
          />
        </g>
        <defs>
          <clipPath id="clip0_1_3641">
            <rect width={24} height={24} fill="white" />
          </clipPath>
        </defs>
      </svg>
      )}
      {icon==="icon9" && (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={21}
        height={20}
        viewBox="0 0 21 20"
        fill="none"
      >
        <path d="M4 9H0V20H4V9Z" fill="#007DC1" />
        <path
          d="M15 1.25C15.65 0.49 16.66 0 17.7 0C19.55 0 21 1.45 21 3.3C21 5.57 18.09 8.2 15 11C11.91 8.19 9 5.56 9 3.3C9 1.45 10.45 0 12.3 0C13.34 0 14.35 0.49 15 1.25Z"
          fill="#007DC1"
        />
        <path
          d="M19 15H12L9.91 14.27L10.24 13.33L12 14H14.82C15.47 14 16 13.47 16 12.82C16 12.33 15.69 11.89 15.23 11.71L7.97 9H6V18.02L13 20L21.01 17C21 15.9 20.11 15 19 15Z"
          fill="#007DC1"
        />
      </svg>
      )}
      {icon === "icon10" && (
         <svg
         xmlns="http://www.w3.org/2000/svg"
         width={24}
         height={24}
         viewBox="0 0 24 24"
         fill="none"
       >
         <g clipPath="url(#clip0_1_3709)">
           <path
             d="M9 5V7H15.59L4 18.59L5.41 20L17 8.41V15H19V5H9Z"
             fill="#007DC1"
           />
         </g>
         <defs>
           <clipPath id="clip0_1_3709">
             <rect width={24} height={24} fill="white" />
           </clipPath>
         </defs>
       </svg>
      )}
    </div>
  );