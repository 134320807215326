import React from 'react'
import { Modal } from 'react-bootstrap'

export default function TalktoExpertModal({modalShow,setModalShow}) {
  return (
    <Modal
    show={modalShow}
    centered
    size='lg'
    style={{ marginTop: '60px' }}
    scrollable={true}
    className='modal-dialog-scrollable p-6 modal_mobile'
    onHide={() => setModalShow(false)}>
    <div style={{ position: "absolute", right: "2rem", top: ".8rem", cursor: "pointer", }}
        onClick={() => setModalShow(false)}
    ><i className="fa-solid fa-x"></i></div>
    <iframe
        frameBorder='0'
        style={{
            height: '500px',
            width: '99%',
            border: 'none',
        }}
        title='Talk to an expert'
        src='https://forms.zohopublic.com/floorwalk/form/MysteryShoppingServices/formperma/xW3CS_zHKW2DcCLHVZUplXsC1wLR_fITFv3kCFrBY9U?zf_rszfm=1'></iframe>
</Modal>
  )
}
