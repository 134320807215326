import React from 'react'
import Header1 from '../Header1';
import { Outlet } from 'react-router-dom'
import Footer from '../Footer';
import HeaderNew1 from '../header/HeaderNew1';

export default function AccountInfoMain() {
  return (
    <div>
      {/* <Header1 /> */}
      <HeaderNew1/>
      <Outlet />
      <Footer />
    </div>
  )
}
