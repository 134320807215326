import { configureStore } from "@reduxjs/toolkit";
import cartSlice from "./cartSlice";
import orderdataslice from "./orderdataslice";
import draftDataSlice from "./draftDataSlice";
import stepSlice from "./stepSlice";
import locationReducer from "./locationSlice";
import authReducer from "./authSlice";

const store = configureStore({
    reducer: {
        cart: cartSlice,
        orderdata: orderdataslice,
        draftData: draftDataSlice,
        step: stepSlice,
        locations: locationReducer,
        authData : authReducer,
    },
})

export default store;